import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { userLogin } from "../../reducers/userInfo";
import AlertModal from '../../components/alertModal';
import { Config } from "../../common/config";
import axios from "axios";
import translations from "../../data/translation";

export default function Login(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const receivedData = location.state;
    const [modalOpen, setModalOpen] = useState(false);
    const [modalInfo, setModalInfo] = useState({ isCancel: true, isConfirm: true, isMessage: true, headerMsg: '', actionFlag: '', mainMsg: '', action: '', isPercent: false });
    const [data, setData] = useState([]);
    const userInfo = useSelector((state) => state.userInfo);
    const language = useSelector((state) => state.language.language); // Redux에서 언어 상태 가져오기
    const [loginInfo, setLoginInfo] = useState({ loginId: '', loginPwd: '' });
    const [user, setUser] = useState({ loginId: '', loginPwd: '', jwt: '' });
    const [keepLoggedIn, setKeepLoggedIn] = useState(false);
    
    const t = translations[language];

    const closeModal = () => {
        setModalOpen(false);
    };

    const doModal = () => {
        setModalOpen(false);
        goLogin();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUser(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCheckboxChange = (e) => {
        setKeepLoggedIn(e.target.checked);
    };

    const getUser = async (jwtToken) => {
        try {
            const response = await axios.get(`${Config.API_SERVER}user`, {
                headers: {
                    Authorization: "Bearer " + jwtToken,
                },
            });

            if (response && response.data && response.data.data) {
                const userInfo = response.data.data;

                let storeName = null;
                if (userInfo.storeNo) {
                    try {
                        const storeResponse = await axios.get(
                            `${Config.API_SERVER}store/${userInfo.storeNo}`,
                            {
                                headers: {
                                    Authorization: `Bearer ${jwtToken}`,
                                },
                            }
                        );

                        if (storeResponse && storeResponse.data && storeResponse.data.data) {
                            storeName = storeResponse.data.data.storeName;
                        }
                    } catch (storeError) {
                        console.log("Error fetching store info:", storeError);
                    }
                }

                const user = {
                    userNo: userInfo.userNo,
                    id: userInfo.userId,
                    pwd: userInfo.pwd,
                    jwtToken: jwtToken,
                    name: userInfo.userName,
                    mobile: userInfo.mobile,
                    zipCode: userInfo.zipCode,
                    addr: userInfo.addr,
                    detAddr: userInfo.detAddr,
                    role: userInfo.role,
                    storeNo: userInfo.storeNo,
                    storeName: storeName,
                };

                dispatch(userLogin(user));
            }
        } catch (e) {
            console.log(e);
        }
    };

    const goLogin = async () => {
        console.log(user);
        try {
            const response = await axios.post(`${Config.API_SERVER}auth/getuser`,
                {
                    userId: user.loginId,
                    pwd: user.loginPwd,
                });
            const userLoginData = {
                id: user.loginId,
                pwd: user.loginPwd,
                jwtToken: response?.data?.data?.accessToken
            };

            console.log(userLoginData);
            getUser(response?.data?.data?.accessToken);

            if (keepLoggedIn) {
                localStorage.setItem('autologin', 'true');
            } else {
                localStorage.removeItem('autologin');
            }

            navigate('/gift-list');

        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        // 자동로그인 로컬스토리지 값 설정
        const autoLogin = localStorage.getItem('autologin') === 'true';
        setKeepLoggedIn(autoLogin);
        if (userInfo.id) {
            navigate("/gift-list");
        }
    }, []);

    return (
        <>
            {/* 모달 구성 */}
            <AlertModal open={modalOpen} close={closeModal} action={doModal} header={modalInfo.headerMsg}
                isConfirm={modalInfo.isConfirm} isMessage={modalInfo.isMessage} isCancel={modalInfo.isCancel}>
                {modalInfo.mainMsg}
            </AlertModal>

            <section className="location_box">
                <ul className="location_001">
                    <li>{t.home}</li>
                    <li>{t.adminLogin}</li>
                </ul>
            </section>

            <main>
                <section className="contants_box_04">
                    <article className="signup_07">
                        <h2 className="tit_01">{t.mainTitle}</h2>
                    </article>

                    <article className="contants_box_03">
                        <table className="login_box">
                            <tbody>
                                <tr>
                                    <th>{t.loginId}</th>
                                    <td>
                                        <input
                                            className="input_03"
                                            type="text"
                                            name="loginId"
                                            value={user.loginId}
                                            onChange={handleInputChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t.loginPassword}</th>
                                    <td>
                                        <input
                                            className="input_03"
                                            type="password"
                                            name="loginPwd"
                                            value={user.loginPwd}
                                            onChange={handleInputChange}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </article>

                    <div className="signup_08">
                        <label className="check_box">{t.keepLoggedIn}
                            <input
                                type="checkbox"
                                checked={keepLoggedIn}
                                onChange={handleCheckboxChange}
                            />
                            <span className="check_check"></span>
                        </label>

                        <article className="button_box_01">
                            <button type="button" className="button_01 button_06 button_red" onClick={goLogin}>{t.loginButton}</button>
                        </article>
                        <article className="button_box_01">
                            <NavLink to='/login-idsearch'>
                                <br />
                                <button type="button" className="button_01 button_07 button_gray_line">{t.findUsername}</button>
                            </NavLink>
                            <NavLink to='/login-pwdsearch'>
                                <br />
                                <button type="button" className="button_01 button_07 button_gray_line">{t.findPassword}</button>
                            </NavLink>
                        </article>
                        <article className="button_box_01">
                            <NavLink to='/user-signup'>
                                <button type="button" className="button_01 button_06 button_gray_line_bg">{t.signUp}</button>
                            </NavLink>
                        </article>
                    </div>

                </section>
            </main>
        </>
    );
}
