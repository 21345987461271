import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Config } from "../../common/config";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import AlertModal from "../../components/alertModal";
import { getPostcode } from "../../components/zip";
import LeftMenu from "../../components/leftMenu";
import { getFormattedDateTime } from "../../components/utils";
import { userLogin } from "../../reducers/userInfo";

export default function UserManage(props) {
  var script = document.createElement("script");
  script.src = "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
  // script.src = Config.ADDRESS_SRC;
  document.head.appendChild(script);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo);
  // console.log("userInfo : "+JSON.stringify(userInfo));

  const [modalOpen, setModalOpen] = useState(false); //모달 상태 관리 : 기본값 - 닫힘
  const [modalInfo, setModalInfo] = useState({
    isCancel: false,
    isConfirm: true,
    isMessage: true,
    headerMsg: "",
    actionFlag: "",
    error: "",
    mainMsg: "",
    action: "",
    isPercent: false,
  });
  const [userData, setUserData] = useState({});

  // const [id, setId] = useState('');
  // const [name, setName] = useState('');
  // const [email, setEmail] = useState('');
  // const [gender, setGender] = useState('M');
  // const [acceptAgree, setAcceptAgree] = useState('RA01');
  // // const [zipCode, setzipCode] = useState('');
  // // const [addr, setAddr] = useState('');

  const [emailAuthNo, setEmailAuthNo] = useState(0);
  const [smsAuthNo, setSmsAuthNo] = useState(0);
  const [verifyId, setVerifyId] = useState(true);
  const [verifySamePassword, setVerifySamePassword] = useState(true);
  const [verifyEmail, setVerifyEmail] = useState(true);
  const [verifySMS, setVerifySMS] = useState(false);

  let sms = "";
  let modUserData = {};
  const [birthday, setBirthday] = useState("");
  const [detailAddr, setDetailAddr] = useState("");
  const [regDttm, setRegDttm] = useState("");
  const [updDttm, setUpdDttm] = useState("");
  const [sms1, setSms1] = useState("");
  const [sms2, setSms2] = useState("");
  const [sms3, setSms3] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const openModal = (e) => {
    //     if(modalInfo.actionFlag === 'mod'){
    //         // console.log("===== mod");
    //         setModalInfo({...modalInfo, actionFlag:'mod', mainMsg:'정말 수정하시겠습니까?'});
    //    }else
    if (modalInfo.actionFlag === "verifySamePassword") {
      setModalInfo({
        ...modalInfo,
        mainMsg: "비밀번호가 일치하지 않습니다(대소문자 구분).",
      });
    } else if (modalInfo.actionFlag === "mail") {
      setModalInfo({ ...modalInfo, mainMsg: `메일을 보내는 중입니다.....` });
    } else if (modalInfo.actionFlag === "getEmailAuth") {
      setModalInfo({
        ...modalInfo,
        mainMsg: "이메일 인증번호받기를 해주세요.",
      });
    } else if (modalInfo.actionFlag === "verifyEmailError") {
      setModalInfo({
        ...modalInfo,
        mainMsg: "이메일 인증번호가 다릅니다. 다시 해주세요",
      });
    } else if (modalInfo.actionFlag === "verifyEmail") {
      setModalInfo({ ...modalInfo, mainMsg: "이메일 인증 해주세요." });
    } else if (modalInfo.actionFlag === "SMS") {
      setModalInfo({ ...modalInfo, mainMsg: `SMS를 보내는 중입니다.....` });
    } else if (modalInfo.actionFlag === "getSMSAuth") {
      setModalInfo({
        ...modalInfo,
        mainMsg: "휴대폰 인증번호받기를 해주세요.",
      });
    } else if (modalInfo.actionFlag === "verifySMSError") {
      setModalInfo({
        ...modalInfo,
        mainMsg: "휴대폰 인증번호가 다릅니다. 다시 해주세요",
      });
    } else if (modalInfo.actionFlag === "verifySMS") {
      setModalInfo({ ...modalInfo, mainMsg: "휴대폰 번호 인증 해주세요." });
    } else if (modalInfo.actionFlag === "api") {
      setModalInfo({
        ...modalInfo,
        mainMsg: "API 서버에러입니다. 잠시 후 다시 시도해주세요.",
      });
    }

    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    if (modalInfo.actionFlag === "pwd") {
      setPassword("");
      setNewPassword("");
      setNewPassword1("");
      setNewPassword2("");
      modalInfo.error = "";
      modalInfo.isMessage = true;
    }
  };

  const doModal = () => {
    // console.log("doModal actionFlag:"+modalInfo.actionFlag);
    setModalOpen(false);
    // if(modalInfo.actionFlag === 'mod'){
    //     modalInfo.actionFlag ='' ;
    //     modUser();
    // }
    if (modalInfo.actionFlag === "pwd") {
      modalInfo.actionFlag = "";
      // 바뀐 비밀번호값 넣기
      doChangePwd();
      modalInfo.isMessage = true;
    } else if (modalInfo.actionFlag === "login") {
      navigate("/login");
    }
  };

  const doChangePwd = () => {
    // console.log("doChangePwd");

    if (password !== userData.pwd) {
      setModalInfo({
        ...modalInfo,
        actionFlag: "pwd",
        isMessage: false,
        error: "등록된 비밀번호와 다릅니다.",
      });
      setModalOpen(true);
      return;
    }
    if (password === newPassword1) {
      setModalInfo({
        ...modalInfo,
        actionFlag: "pwd",
        isMessage: false,
        error: "기존 비밀번호와 신규 비밀번호를 다르게 입력하세요.",
      });
      setModalOpen(true);
      return;
    }
    if (!validatePassword(newPassword1)) {
      setModalInfo({
        ...modalInfo,
        actionFlag: "pwd",
        isMessage: false,
        error: `신규 비밀번호는 영문과 숫자 또는 특수문자 조합 8~16자이내로 입력하세요.\n
                개인정보가 포함되거나 4자이상 연속된 동일한 문자 또는 숫자는 비밀번호로 사용이 불가합니다.`,
      });
      setModalOpen(true);
      return;
    }
    if (newPassword1 !== newPassword2) {
      setModalInfo({
        ...modalInfo,
        actionFlag: "pwd",
        isMessage: false,
        error: "신규 비밀번호와 신규 비밀번호 확인을 같게 입력하세요.",
      });
      setModalOpen(true);
      return;
    }

    setNewPassword(newPassword1);
  };

  const refetchJwt = async (refetchmod) => {
    try {
      const response = await axios.post(`${Config.API_SERVER}auth/getuser`, {
        userId: userInfo.id,
        pwd: userInfo.pwd,
      });

      console.log("jwt재발급: ", response.data.data.accessToken);
      const jwtToken = response.data.data.accessToken;
      const user = {
        ...userInfo,
        jwtToken: jwtToken,
      };

      dispatch(userLogin(user));
      if (refetchmod == "GET_DATA") {
        console.log("재발급후 회원정보가져옴");
        getData(jwtToken);
      } else {
        console.log("재발급후 회원수정");
        modUser(jwtToken);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getData = async (newToken) => {
    console.log(userInfo.jwtToken);
    try {
      // console.log("token : "+userInfo.jwtToken);
      if (userInfo.jwtToken) {
        const ret = await axios.get(Config.API_SERVER + "user", {
          headers: {
            Authorization: `Bearer ${newToken ? newToken : userInfo.jwtToken}`,
          },
        });
        console.log(ret);
        if (ret?.data?.data) {
          // console.log("data : "+JSON.stringify(ret.data.data ));
          setUserData(ret.data.data);
          setBirthday(ret.data.data.birthday.slice(0, 10));
          if (ret.data.data.mobile) {
            const regex = /^(\d{3})-(\d{4})-(\d{4})$/;
            const [, m1, m2, m3] = ret.data.data.mobile.match(regex);

            setSms1(m1);
            setSms2(m2);
            setSms3(m3);
          }
          setDetailAddr(ret.data.data.detAddr || "");
          setRegDttm(getFormattedDateTime(ret.data.data.regDttm));
          setUpdDttm(getFormattedDateTime(ret.data.data.updDttm));
        }
      }
    } catch (error) {
      
      if (error.response.data.resultCode === 401) {
        const autologin = localStorage.getItem("autologin");
        console.log(autologin);
        if (autologin) {
          refetchJwt("GET_DATA");
        } else {
          modalInfo.actionFlag = "login";
          modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
          setModalOpen(true);
        }
      }
      console.error("Error getData:", error);
    }
  };

  // const getFormattedDateTime = (rawDateTime) => {
  //     // 현재 날짜 및 시간 가져오기
  //     const currentDate = new Date(rawDateTime);

  //     // 년, 월, 일 가져오기
  //     const year = currentDate.getFullYear();
  //     const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1, 두 자리로 만들기
  //     const day = String(currentDate.getDate()).padStart(2, '0'); // 두 자리로 만들기

  //     // 시, 분, 초, 밀리초 가져오기
  //     const hours = String(currentDate.getHours()).padStart(2, '0'); // 두 자리로 만들기
  //     const minutes = String(currentDate.getMinutes()).padStart(2, '0'); // 두 자리로 만들기
  //     const seconds = String(currentDate.getSeconds()).padStart(2, '0'); // 두 자리로 만들기
  //     const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0'); // 세 자리로 만들기

  //     // 포맷된 문자열 생성
  //     const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;

  //     // console.log(formattedDateTime);
  //     return formattedDateTime;
  // }

  const validatePassword = (pwd) => {
    // console.log(pwd);
    // console.log(pwd.length);
    // 비밀번호의 길이가 8에서 16자 사이인지 확인
    if (pwd.length === 0 || pwd.length < 8 || pwd.length > 16) {
      return false;
    } else {
      // 영문, 숫자, 특수문자 중 2가지 이상을 포함하는지 확인
      let hasUpperCase = false;
      let hasLowerCase = false;
      let hasNumber = false;
      let hasSpecialChar = false;

      const specialChars = "!\"#$%&'()*+,-./:;>=<?@[\\]^_`{|}~";

      for (let i = 0; i < pwd.length; i++) {
        const char = pwd.charAt(i);

        if (char >= "a" && char <= "z") {
          hasLowerCase = true;
        } else if (char >= "A" && char <= "Z") {
          hasUpperCase = true;
        } else if (char >= "0" && char <= "9") {
          hasNumber = true;
        } else if (specialChars.includes(char)) {
          hasSpecialChar = true;
        }
      }

      const conditions = [
        hasUpperCase,
        hasLowerCase,
        hasNumber,
        hasSpecialChar,
      ];
      const countTrue = conditions.filter((condition) => condition).length;
      // console.log("2 : "+countTrue);
      // console.log("2 : hasUpperCase "+hasUpperCase);
      // console.log("2 : hasLowerCase "+hasLowerCase);
      // console.log("2 : hasNumber "+hasNumber);
      // console.log("2 : hasSpecialChar "+hasSpecialChar);

      if (countTrue < 2) {
        return false;
      } else {
        // 동일한 문자가 4번 이상 연속으로 나오는지 확인
        for (let i = 0; i < pwd.length - 3; i++) {
          if (
            pwd[i] === pwd[i + 1] &&
            pwd[i] === pwd[i + 2] &&
            pwd[i] === pwd[i + 3]
          ) {
            return false;
          }
        }
      }
    }
    return true;
  };

  const getEmailAuthNo = async () => {
    try {
      if (userData.email === null || userData.email === undefined) {
        setModalInfo({
          ...modalInfo,
          isConfirm: true,
          mainMsg: "메일을 입력하신 후 다시 해주세요!",
        });
        setModalOpen(true);
        return;
      }
      if (userData.email.length > 0) {
        modUserData.email = userData.email;
        modalInfo.actionFlag = "mail";
        modalInfo.isConfirm = false;
        openModal();
        const data = {
          userId: userData.userId,
          userName: userData.userName,
          pwd: userData.pwd,
          email: userData.email,
        };
        // console.log("data : "+JSON.stringify(data));
        const ret = await axios.post(
          `${Config.API_SERVER}user/sendEmail`,
          data
        );
        // console.log(JSON.stringify(ret.data));
        if (ret.data.data.emailAuthNo) {
          setEmailAuthNo(ret.data.data.emailAuthNo);
          setModalOpen(false);
          setModalInfo({
            ...modalInfo,
            isConfirm: true,
            mainMsg: "인증번호를 메일에서 확인하고 넣어주세요!",
          });
          setModalOpen(true);
        } else {
          // API 에러...
          setModalOpen(false);
          modalInfo.actionFlag = "api";
          modalInfo.isConfirm = true;
          openModal();
        }
      } else {
        setModalInfo({
          ...modalInfo,
          isConfirm: true,
          mainMsg: "메일을 입력하신 후 다시 해주세요!",
        });
        setModalOpen(true);
        return;
      }
    } catch (error) {
      if (error.response.data.resultCode === 401) {
        modalInfo.actionFlag = "login";
        modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
        setModalOpen(true);
      }
      console.error("Error getData:", error);
    }
  };

  const validateEmailAuthNo = async () => {
    try {
      if (emailAuthNo > 0) {
        const data = { userId: userData.userId, emailAuthNo };
        console.log("data : " + JSON.stringify(data));
        const ret = await axios.post(
          `${Config.API_SERVER}user/verifyEmail`,
          data
        );
        // console.log(JSON.stringify(ret));
        if (ret?.data?.data?.verifyResult) {
          setVerifyEmail(true);
          setModalInfo({
            ...modalInfo,
            mainMsg: "이메일 인증번호가 확인 되었습니다.",
          });
          setModalOpen(true);
        } else {
          // API 에러...
          modalInfo.actionFlag = "verifyEmailError";
          openModal();
        }
      } else {
        modalInfo.actionFlag = "getEmailAuth";
        openModal();
      }
    } catch (error) {
      if (error.response.data.resultCode === 401) {
        modalInfo.actionFlag = "login";
        modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
        setModalOpen(true);
      }
      console.error("Error getData:", error);
    }
  };

  const validateSMS = () => {
    // console.log("validateSMS");
    // 휴대폰번호의 길이 확인
    if (sms1.length !== 3 || sms2.length !== 4 || sms3.length !== 4) {
      modalInfo.mainMsg =
        "휴대폰 번호를 숫자(3자리)-숫자(4자리)-숫자(3자리)로 넣어주세요.";
      return false;
    } else {
      // 숫자인지 확인
      const hasNumber1 = !isNaN(sms1);
      const hasNumber2 = !isNaN(sms2);
      const hasNumber3 = !isNaN(sms3);
      // console.log(hasNumber1);
      // console.log(hasNumber2);
      // console.log(hasNumber3);
      if (hasNumber1 & hasNumber2 & hasNumber3) {
        sms = sms1 + "-" + sms2 + "-" + sms3;
        return true;
      } else {
        modalInfo.mainMsg =
          "휴대폰 번호를 숫자(3자리)-숫자(4자리)-숫자(3자리)로 넣어주세요.";
        return false;
      }
    }
  };

  const getSMSAuthNo = async () => {
    try {
      const validSMS = validateSMS();
      if (validSMS) {
        console.log("SMS : " + sms);
        const data = { userId: userData.userId, mobile: sms };
        console.log("data : " + JSON.stringify(data));
        const ret = await axios.post(`${Config.API_SERVER}user/sendSMS`, data);
        // console.log(JSON.stringify(ret));
        if (ret?.data?.data?.smsAuthNo) {
          setSmsAuthNo(ret.data.data.smsAuthNo);
          setModalInfo({
            ...modalInfo,
            mainMsg: "인증번호를 SMS로 확인하고 넣어주세요.",
          });
          setModalOpen(true);
        } else {
          // API 에러...
          modalInfo.actionFlag = "api";
          openModal();
        }
      } else {
        console.log("SMS Error");
        setModalInfo({ ...modalInfo });
        setModalOpen(true);
      }
    } catch (error) {
      if (error.response.data.resultCode === 401) {
        modalInfo.actionFlag = "login";
        modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
        setModalOpen(true);
      }
      console.error("Error getData:", error);
    }
  };

  const validateSMSAuthNo = async () => {
    try {
      if (smsAuthNo > 0) {
        const data = { userId: userData.userId, smsAuthNo };
        console.log("data : " + JSON.stringify(data));
        const ret = await axios.post(
          `${Config.API_SERVER}user/verifySMS`,
          data
        );
        // console.log(JSON.stringify(ret));
        if (ret.data.data.verifyResult) {
          setVerifySMS(true);
          setModalInfo({
            ...modalInfo,
            mainMsg: "휴대폰인증번호가 확인 되었습니다.",
          });
          setModalOpen(true);
        } else {
          // API 에러...
          modalInfo.actionFlag = "verifySMSError";
          openModal();
        }
      } else {
        modalInfo.actionFlag = "getSMSAuth";
        openModal();
      }
    } catch (error) {
      if (error.response.data.resultCode === 401) {
        modalInfo.actionFlag = "login";
        modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
        setModalOpen(true);
      }
      console.error("Error getData:", error);
    }
  };

  const validateEmail = (email) => {
    // 빈 문자열 검사
    // if (!email) {
    //     modalInfo.mainMsg = '이메일 주소를 입력하세요.';
    //     setModalOpen(true);
    // }

    // 문자열 형식 검사
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  const checkUserData = () => {
    // console.log("checkUserData");

    // if(!password)
    // {
    //     modalInfo.mainMsg = '비밀번호를 입력해 주세요.';
    //     return false;
    // }
    // if(!verifySamePassword)
    // {
    //     modalInfo.mainMsg = '비밀번호 확인 해주세요.';
    //     return false;
    // }
    if (!userData.userName) {
      modalInfo.mainMsg = "이름를 입력해 주세요.";
      return false;
    }
    if (!birthday) {
      modalInfo.mainMsg = "생년월일을 입력해 주세요.";
      return false;
    }
    // if(!gender)
    // {
    //     modalInfo.mainMsg = '성별을 반드시 선택하세요!';
    //     return false;
    // }
    // email과 SMS 중 반드시 1개는 해야 함.
    // if(!userData.email){
    //     if(!sms)
    //     {
    //         modalInfo.mainMsg = '이메일과 휴대폰번호 중 1가지는 반드시 넣으세요.';
    //         return false;
    //     }else{
    //         if(!verifySMS)
    //         {
    //             modalInfo.mainMsg = '휴대폰번호 인증번호를 받고 인증하기를 하세요.';
    //             return false;
    //         }
    //     }

    // }else{
    //         if (validateEmail() === false) {
    //             modalInfo.mainMsg = '이메일 주소가 유효하지 않습니다.';
    //             return false;
    //         }
    // //     if(!verifyEmail)
    //     {
    //         modalInfo.mainMsg = '이메일 인증번호를 받고 인증하기를 하세요.';
    //         return false;
    //     }
    // }

    if (userData.email) {
      if (validateEmail(userData.email) === false) {
        modalInfo.mainMsg = "이메일 주소가 유효하지 않습니다.";
        return false;
      }
    }
    if (sms1) {
      const validSMS = validateSMS();
      if (!validSMS) {
        return false;
      }
    }

    return true;
  };

  const modUser = async (newToken) => {
    try {
      if (checkUserData() === true) {
        // console.log(" true ");

        // console.log(userData.userName);
        modUserData.userName = userData.userName;
        if (newPassword) {
          modUserData.pwd = newPassword;
        }
        modUserData.birthday = birthday;
        modUserData.gender = userData.gender;
        if (userData.email.length > 0) modUserData.email = userData.email;
        if (sms.length > 0) {
          modUserData.mobile = sms;
        }
        if (document.getElementById("postcode").value > 0) {
          modUserData.zipCode = document.getElementById("postcode").value;
          modUserData.addr = document.getElementById("roadAddress").value;
        }
        if (detailAddr.length > 0) {
          modUserData.detAddr = detailAddr;
        }

        console.log(" modUserData : " + JSON.stringify(modUserData));

        // 관리자 정보 저장
        const ret = await axios.put(`${Config.API_SERVER}user`, modUserData, {
          headers: {
            Authorization: `Bearer ${newToken ? newToken : userInfo.jwtToken}`,
          },
        });
        console.log("Data : " + JSON.stringify(ret.data));

        setModalInfo({
          ...modalInfo,
          mainMsg: "요청정보를 성공적으로 수정하였습니다.",
        });
        setModalOpen(true);
      } else {
        setModalInfo({ ...modalInfo });
        setModalOpen(true);
      }
    } catch (error) {
      // 에러 발생 시의 코드
      // console.error("Error fetching data : ", JSON.stringify(error));
      if (error.response) {
        if (error.response.data.resultCode === 401) {
          const autologin = localStorage.getItem("autologin");
          if (autologin) {
            refetchJwt("USER_MODIFY");
          } else {
            modalInfo.actionFlag = "login";
            modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
            setModalOpen(true);
          }
        } else if (error.request) {
          // 요청은 전송되었지만 응답이 없는 경우
          // alert("No response received from the server.");
          modalInfo.actionFlag = "api";
          openModal();
        } else {
          // 요청을 설정하는 중에 에러가 발생한 경우
          // alert("Error setting up the request.");
          modalInfo.actionFlag = "api";
          openModal();
        }
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    getData();
  }, [userInfo.jwtToken]);

  return (
    <>
      <AlertModal
        open={modalOpen}
        close={closeModal}
        action={doModal}
        header={modalInfo.headerMsg}
        error={modalInfo.error}
        isConfirm={modalInfo.isConfirm}
        isMessage={modalInfo.isMessage}
        isCancel={modalInfo.isCancel}
      >
        {modalInfo.actionFlag === "pwd" ? (
          <table class="login_box">
            <tbody>
              <tr>
                <th>기존 비밀번호</th>
                <td>
                  <input
                    class="input_03"
                    type="text"
                    name=""
                    maxLength={16}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    value={password}
                  />
                </td>
              </tr>
              <tr>
                <th>신규 비밀번호</th>
                <td>
                  <input
                    class="input_03"
                    type="password"
                    name=""
                    maxLength={16}
                    onChange={(e) => {
                      setNewPassword1(e.target.value);
                    }}
                    value={newPassword1}
                  />
                </td>
              </tr>
              <tr>
                <th>신규 비밀번호 확인</th>
                <td>
                  <input
                    class="input_03"
                    type="password"
                    name=""
                    maxLength={16}
                    onChange={(e) => {
                      setNewPassword2(e.target.value);
                    }}
                    value={newPassword2}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <>{modalInfo.mainMsg}</>
        )}
      </AlertModal>

      <section className="location_box">
        <ul className="location_001">
          <li>홈</li>
          <li>마이페이지</li>
          <li>관리자 정보 관리</li>
        </ul>
      </section>

      <main>
        <section className="contants_box contants_box_mypage">
          <LeftMenu pageName="user" />

          <section className="rightcontant_box">
            <h2 className="tit_01">관리자 정보 관리</h2>

            <article className="contants_box_03">
              <table className="member_box">
                <tbody>
                  <tr>
                    <th>아이디</th>
                    <td>
                      <form>
                        <input
                          className="input_01"
                          type="text"
                          style={{ backgroundColor: "#ddd" }}
                          name=""
                          disabled
                          value={userData ? userData.userId : ""}
                        />
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th>비밀번호</th>
                    <td>
                      <button
                        type="button"
                        className="button_05 button_gray"
                        onClick={(e) => {
                          modalInfo.actionFlag = "pwd";
                          modalInfo.isMessage = false;
                          openModal(e);
                        }}
                      >
                        비밀번호 변경
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <th>이름</th>
                    <td>
                      <form>
                        <input
                          className="input_01"
                          type="text"
                          name=""
                          maxLength={80}
                          value={userData ? userData.userName : ""}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              userName: e.target.value,
                            })
                          }
                        />
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th>생년월일</th>
                    <td>
                      <form>
                        <input
                          className="input_01"
                          type="date"
                          name=""
                          value={birthday ? birthday : ""}
                          onChange={(e) => setBirthday(e.target.value)}
                        />
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th>성별</th>
                    <td>
                      <form>
                        <select
                          className="input_01"
                          name="gender"
                          value={userData ? userData.gender : ""}
                          onChange={(e) => {
                            setUserData({
                              ...userData,
                              gender: e.target.value,
                            });
                          }}
                        >
                          {/* <option value="" disabled>선택</option> */}
                          <option
                            value="M"
                            selected={userData ? userData.gender === "M" : ""}
                          >
                            남자
                          </option>
                          <option
                            value="F"
                            selected={userData ? userData.gender === "F" : ""}
                          >
                            여자
                          </option>
                        </select>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th>이메일</th>
                    <td>
                      <form>
                        <input
                          className="input_01"
                          type="text"
                          name=""
                          maxLength={80}
                          placeholder="예)naegift@authrium.com"
                          value={userData ? userData.email : ""}
                          onChange={(e) =>
                            setUserData({ ...userData, email: e.target.value })
                          }
                        />
                        <button
                          type="button"
                          className="button_05 button_gray"
                          onClick={getEmailAuthNo}
                        >
                          인증번호받기
                        </button>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th>이메일 인증번호</th>
                    <td>
                      <form>
                        <input
                          className="input_01"
                          type="text"
                          name=""
                        />
                        <button
                          type="button"
                          className="button_05 button_gray"
                          onClick={validateEmailAuthNo}
                        >
                          인증하기
                        </button>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th>휴대폰번호</th>
                    <td>
                      <form>
                        <input
                          className="input_02"
                          type="text"
                          name=""
                          maxLength={3}
                          value={sms1 ? sms1 : ""}
                          onChange={(e) => {
                            setSms1(e.target.value);
                          }}
                        />
                        -
                        <input
                          className="input_02"
                          type="text"
                          name=""
                          maxLength={4}
                          value={sms2 ? sms2 : ""}
                          onChange={(e) => {
                            setSms2(e.target.value);
                          }}
                        />
                        -
                        <input
                          className="input_02"
                          type="text"
                          name=""
                          maxLength={4}
                          value={sms3 ? sms3 : ""}
                          onChange={(e) => {
                            setSms3(e.target.value);
                          }}
                        />
                        <button
                          type="button"
                          className="button_05 button_gray"
                          onClick={getSMSAuthNo}
                        >
                          인증번호받기
                        </button>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th>휴대폰번호 인증번호</th>
                    <td>
                      <form>
                        <input
                          className="input_01"
                          type="text"
                          name=""
                        />
                        <button
                          type="button"
                          className="button_05 button_gray"
                          onClick={validateSMSAuthNo}
                        >
                          인증하기
                        </button>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th>주소</th>
                    <td>
                      <div className="signup_04">
                        <form>
                          <input
                            className="input_01"
                            type="text"
                            id="postcode"
                            style={{ backgroundColor: "#ddd" }}
                            disabled
                            placeholder="우편번호"
                            value={userData ? userData.zipCode : ""}
                          />
                          <button
                            type="button"
                            className="button_05 button_gray"
                            onClick={getPostcode}
                          >
                            찾기
                          </button>
                          <br />
                          <input
                            className="input_03"
                            type="text"
                            id="roadAddress"
                            style={{ backgroundColor: "#ddd" }}
                            disabled
                            placeholder="도로명주소"
                            value={userData ? userData.addr : ""}
                          />
                          <input
                            className="input_03"
                            type="text"
                            id="jibunAddress"
                            disabled
                            placeholder="지번주소"
                            hidden={true}
                          />
                          <span
                            id="guide"
                            style={{ color: "#999", display: "none" }}
                          ></span>
                          {/* <br /> */}
                          <input
                            className="input_03"
                            type="text"
                            id="detailAddress"
                            maxLength={64}
                            placeholder="상세주소"
                            value={detailAddr ? detailAddr : ""}
                            onChange={(e) => setDetailAddr(e.target.value)}
                          />
                          <input
                            className="input_03"
                            type="text"
                            id="extraAddress"
                            disabled
                            placeholder="참고항목"
                            hidden={true}
                          />
                        </form>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>가입일시</th>
                    <td>
                      <form>
                        <input
                          className="input_01"
                          style={{ backgroundColor: "#ddd" }}
                          type="text"
                          name=""
                          disabled
                          value={regDttm ? regDttm : ""}
                        />
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th>수정일시</th>
                    <td>
                      <form>
                        <input
                          className="input_01"
                          style={{ backgroundColor: "#ddd" }}
                          type="text"
                          name=""
                          disabled
                          value={updDttm ? updDttm : ""}
                        />
                      </form>
                    </td>
                  </tr>
                </tbody>
              </table>
            </article>

            <article className="button_box_01">
              <NavLink to="/user-withdrawal">
                <button
                  type="button"
                  className="button_01 button_03 button_gray_line font_red"
                >
                  회원탈퇴
                </button>
              </NavLink>
              <NavLink to="/gift-list">
                <button
                  type="button"
                  className="button_01 button_03 button_gray_line"
                >
                  취소
                </button>
              </NavLink>
              <button
                type="button"
                className="button_01 button_03 button_red"
                onClick={(e) => {
                  // modalInfo.actionFlag = 'mod';
                  // modalInfo.mainMsg = '정말 수정하시겠습니까?';
                  // setModalOpen(true);
                  modUser();
                }}
              >
                저장
              </button>
            </article>
          </section>
        </section>
      </main>
    </>
  );
}
