import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation, json } from 'react-router-dom';
import LeftMenu from '../../components/leftMenu';
import AlertModal from '../../components/alertModal';
import QRCode from 'qrcode.react';
import { Config } from "../../common/config";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import  { getPostcode }  from '../../components/zip';
import { getFormattedDateTime } from '../../components/utils';
import { userLogin } from "../../reducers/userInfo";
import translations from '../../data/translation';


export default function StoreManage(props) {
    var script = document.createElement('script');
    script.src = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
    // script.src = Config.ADDRESS_SRC;
    document.head.appendChild(script);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo);
    const language = useSelector((state) => state.language.language);
  const t = translations[language];
   
    const [modalOpen, setModalOpen] = useState(false);   //모달 상태 관리 : 기본값 - 닫힘
    const [modalInfo, setModalInfo] = useState({isCancel:false, isConfirm:true, isMessage:true, headerMsg:'', actionFlag:'', mainMsg:'', action:'', isPercent:false});    
    const [storeData, setStoreData] = useState({});
    console.log(storeData);

    let phone = '';
    const [detailAddr, setDetailAddr] = useState('');
    const [regDttm, setRegDttm] = useState('');
    const [updDttm, setUpdDttm] = useState('');
    const [sms1, setSms1] = useState('');
    const [sms2, setSms2] = useState('');
    const [sms3, setSms3] = useState('');
    const [smsP1, setSmsP1] = useState('');
    const [smsP2, setSmsP2] = useState('');
    const [smsP3, setSmsP3] = useState('');
    let smsR1 = '';
    let smsR2 = '';
    let smsR3 = '';
    const [bankCode, setBankCode] = useState('');
    const [bankData, setBankData] = useState([]);
    const [imageFile, setImageFile] = useState(null);
    const [imageFileName, setImageFileName] = useState(null);
    const [isPhoneChecked, setIsPhoneChecked] = useState(false);
    const [isAddrChecked, setIsAddrChecked] = useState(false); 
    const [zipCode, setZipCode] = useState('');
    const [addr, setAddr] = useState('');
    const [zipCodeR, , setZipCodeR] = useState(() => {
        if (userInfo.zipCode) {
            return userInfo.zipCode;
        } else {
            return '';
        }
    });
    const [addrR, setAddrR] = useState(() => {
        if (userInfo.addr) {
            return userInfo.addr;
        } else {
            return '';
        }
    });
    const [detailAddrR, setDetailAddrR] = useState(() => {
        if (userInfo.detAddr) {
            return userInfo.detAddr;
        } else {
            return '';
        }
    });

    if(userInfo.mobile){
        console.log()
        const regex = /^(\d{3})-(\d{4})-(\d{4})$/;
        const [, m1, m2, m3] = userInfo.mobile.match(regex);

        smsR1 = m1;
        smsR2 = m2;
        smsR3 = m3;
        
    }

    const openModal = (e) => {
        // if(modalInfo.actionFlag === 'mod'){
        //     // console.log("===== mod");
        //     setModalInfo({...modalInfo, actionFlag:'mod', mainMsg:'정말 수정하시겠습니까?'});
        //  }
        if(modalInfo.actionFlag === 'del'){
            // console.log("===== del");
            setModalInfo({...modalInfo, actionFlag:'del', isCancel: true, mainMsg:'정말 삭제 하시겠습니까?'});
        }else if(modalInfo.actionFlag === 'api'){
            setModalInfo({...modalInfo, mainMsg:'API 서버에러입니다. 잠시 후 다시 시도해주세요.'});
        }
        
        setModalOpen(true);
     };
     
     const closeModal = () => {
        // console.log("closeModal");
        if(modalInfo.actionFlag === 'del'){
            modalInfo.isCancel = false ;
        }

        setModalOpen(false);
     };
 
     const doModal = () => {
        // console.log("doModal actionFlag:"+modalInfo.actionFlag);
        setModalOpen(false);

        if(modalInfo.actionFlag === 'del'){
            modalInfo.actionFlag ='' ;
            delStore();
        }else if(modalInfo.actionFlag === 'goMain'){
            navigate('/go-main');
        }else if(modalInfo.actionFlag === 'login'){
          navigate('/login');
        }     
    };

     const validatePhone = () => {

            // 숫자인지 확인
             const hasNumber1 = !isNaN(sms1);
            const hasNumber2 = !isNaN(sms2);
            const hasNumber3 = !isNaN(sms3);

            if (hasNumber1 & hasNumber2 & hasNumber3) {
                phone = sms1+"-"+sms2+"-"+sms3;
                return true;
            }else{
                modalInfo.mainMsg = '휴대폰 번호를 숫자-숫자-숫자로 넣어주세요!';
                return false;
            }
        // }
    }

    const phoneCheckboxChange = (e) => {
        setIsPhoneChecked(!isPhoneChecked); 

        if (e.target.checked) {
            if(smsR1) {
                setSms1(smsR1);
                setSms2(smsR2);
                setSms3(smsR3);
            }
        }else{
            console.log(smsP1);
            console.log(smsP2);
            console.log(smsP3);

            if(smsP1) {
                setSms1(smsP1);
                setSms2(smsP2);
                setSms3(smsP3);
            }else{
                setSms1('');
                setSms2('');
                setSms3('');
            }
        }
    };

    const addrCheckboxChange = (e) => {
        setIsAddrChecked(!isAddrChecked);
        if (e.target.checked) {
            if(zipCodeR) {
                setZipCode(zipCodeR);
                setAddr(addrR);
                setDetailAddr(storeData.detailAddrR);
            }
        }else{
            if(storeData.zipCode) {
                setZipCode(storeData.zipCode);
                setAddr(storeData.addr);
                setDetailAddr(storeData.detAddr);
            }else{
                setZipCode('');
                setAddr('');
                setDetailAddr('');
            }
        }
    };

    const bankChange = (e) => {
        setStoreData({ ...storeData, bankCode: e.target.value });
    };

    const refetchJwt = async(refetchmod) => {
      
      try{
      const response = await axios.post(`${Config.API_SERVER}auth/getuser`, {
        userId: userInfo.id,
        pwd:userInfo.pwd
      })
  
      console.log("jwt재발급: ",response.data.data.accessToken)
      const jwtToken = response.data.data.accessToken;
      const user ={ 
        ...userInfo,
        jwtToken: jwtToken
      }
     
      dispatch(userLogin(user));
      if(refetchmod=="GET_BANKDATA"){
        console.log("재발급후 은행정보가져옴");
        getBankData(jwtToken);
      }else if(refetchmod=="MOD_DATA"){
        console.log("재발급후 상점정보수정");
        modStore(jwtToken);
      }
      else if(refetchmod=="DEL_DATA"){
        console.log("재발급후 상점정보삭제");
        delStore(jwtToken);
      }
        else{
        console.log("재발급후 상점정보가져옴");
        getData(jwtToken);
      }
    }catch(e){
      console.log(e);
    }
    }

    const getBankData = async (newToken) => {   
        try{
            const ret = await axios.get(
                `${Config.API_SERVER}code/detail`+
                "?pageSize=20&sortOrd=asc&groupCodeName=은행",
                { headers: { 
                  Authorization: `Bearer ${newToken? newToken : userInfo.jwtToken}`,
                    },
                }
            );
            
            if(ret?.data?.data){
                setBankData(ret.data.data.list);                
            }
        }catch (error) {
          if (error.response.data.resultCode === 401) {
            const autologin = localStorage.getItem("autologin");
            console.log(autologin);
            if(autologin){
              refetchJwt("GET_BANKDATA");                
            }else{
            modalInfo.actionFlag = 'login';
            modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
            setModalOpen(true);
        
            }
          }
            console.error("Error getData:", error);
        }
    }

    const getData = async (newToken) => {   
        console.log("데이터 가져옴")
        try{       
            // console.log("token : "+userInfo.jwtToken); 
            if(userInfo.jwtToken){
                const ret = await axios.get(
                    Config.API_SERVER+"store/"+userInfo.storeNo,
                    { headers: { 
                      Authorization: `Bearer ${newToken? newToken : userInfo.jwtToken}`,
                        },
                    }
                );

                if(ret?.data?.data){
                  
                    // console.log("data : "+JSON.stringify(ret.data.data ));           
                    setStoreData(ret.data.data);
                    // console.log(ret.data.data.thumbnailFileUrl);
                    if(ret.data.data.thumbnailFileUrl !==  Config.API_SERVER){
                        setImageFile(ret.data.data.thumbnailFileUrl);                    
                    }
                    if(ret.data.data.phone){
                
                        const parts = ret.data.data.phone.split('-');

                        setSmsP1(parts[0]);
                        setSmsP2(parts[1]);
                        setSmsP3(parts[2]);
                        setSms1(parts[0]);
                        setSms2(parts[1]);
                        setSms3(parts[2]);
                    }
                    if(ret.data.data.zipCode){
                        setZipCode(ret.data.data.zipCode);
                        setAddr(ret.data.data.addr);
                    }
                    setDetailAddr(ret.data.data.detAddr || '');
                    setRegDttm(getFormattedDateTime(ret.data.data.regDttm));
                    setUpdDttm(getFormattedDateTime(ret.data.data.updDttm));
                    
                }
            }
        }catch (error) {
            
          if (error.response.data.resultCode === 401) {
            const autologin = localStorage.getItem("autologin");
            console.log(autologin);
            if(autologin){
              refetchJwt("GET_DATA");                
            }else{
            modalInfo.actionFlag = 'login';
            modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
            setModalOpen(true);
            }
          }

            console.error("Error getData:", error);
        }
    }

    const checkStoreData = () => {
        // console.log("checkStoreData");

        if(!storeData.storeName)
        {
            modalInfo.mainMsg = '상점명을 입력해 주세요.';
            return false;
        }
        const validPhone = validatePhone();
        if(!validPhone) return false;
        if(!phone)
        {
            modalInfo.mainMsg = '상점 전화번호를 입력해 주세요.';
            return false;
        }
        if(!storeData.zipCode)
        {
            modalInfo.mainMsg = '상점 주소를 입력해 주세요.';
            return false;
        }
        if(!storeData.bankCode)
        {
            modalInfo.mainMsg = '입금받을 은행을 선택해 주세요.';
            return false;
        }
        if(!storeData.accountNo)
        {
            modalInfo.mainMsg = '입금받을 계좌번호를 입력해 주세요.';
            return false;
        }else{
            const hasNumber = !isNaN(storeData.accountNo);
            if (!hasNumber) {          
                modalInfo.mainMsg = '입금받을 계좌번호는 "-" 없이 숫자만 넣어주세요.';
                return false;
            }
        }
        if(!storeData.accountHolder)
        {
            modalInfo.mainMsg = '예금주를 입력해 주세요.';
            return false;
        }
        
        return true;      
    }

    const modStore = async(newToken) => {
        try{
            if (checkStoreData() === true) {
                const formData = new FormData();
              
                formData.append('storeName', storeData.storeName);
                formData.append('phone', phone);
                console.log(document.getElementById('postcode'));
                if(document.getElementById('postcode').value > 0){
                  console.log("통과")
                    formData.append('zipCode', document.getElementById('postcode').value);
                    formData.append('addr', document.getElementById("roadAddress").value);
                  }
                if(detailAddr.length > 0){
                    formData.append('detAddr', detailAddr);
                }
                formData.append('bankCode',  storeData.bankCode);
                formData.append('accountNo', storeData.accountNo);
                formData.append('accountHolder', storeData.accountHolder);       
                console.log(formData)
                // 이미지 파일 추가
                // console.log("storeData.thumbnailFileUrl : "+storeData.thumbnailFileUrl);
                // console.log("imageFile.name : "+imageFile);
                if(imageFile){
                    if(imageFile !== storeData.thumbnailFileUrl){
                        // console.log("file : "+imageFile);
                        // formData.append("file", imageFile);
                        formData.append("file", imageFileName);
                    }
                }
                // console.log(" formData : "+JSON.stringify(formData));
                // for (const item of formData.entries()) {
                //     console.log(item[0] + ': ' + item[1]);
                // }

                // 상점 정보 저장
                
                const ret = await axios.put(`${Config.API_SERVER}store`,
                    formData,
                    { headers: { 
                      Authorization: `Bearer ${newToken? newToken : userInfo.jwtToken}`,
                        },
                    }
                );
                // console.log("Data : "+JSON.stringify(ret.data));
                console.log(formData)

                setModalInfo({...modalInfo, mainMsg: '요청정보를 성공적으로 수정하였습니다.'});
                setModalOpen(true);
            }else{
              console.log("gkgkgk")
                setModalInfo({...modalInfo}); 
                setModalOpen(true);
            }  
        } catch (error) {
            // 에러 발생 시의 코드
            // console.error("Error fetching data : ", JSON.stringify(error));
            if (error.response) {
              if (error.response.data.resultCode === 401) {
                const autologin = localStorage.getItem("autologin");
                console.log(autologin);
                if(autologin){
                  refetchJwt("MOD_DATA");                
                }else{
                modalInfo.actionFlag = 'login';
                modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
                setModalOpen(true);
                }
              }
            } else if (error.request) {
                // 요청은 전송되었지만 응답이 없는 경우
                // alert("No response received from the server.");
                modalInfo.actionFlag = 'api';
                openModal();
            } else {
                // 요청을 설정하는 중에 에러가 발생한 경우
                // alert("Error setting up the request.");
                modalInfo.actionFlag = 'api';
                openModal();
            }
            console.error("Error fetching data:", error);
        } 
    }

    const changeFile = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 3 * 1024 * 1024) { // 3MB 이상인 경우
                e.target.value = null; // 파일 선택 취소
                modalInfo.mainMsg = '이미지 파일 크기는 3MByte를 초과할 수 없습니다.';
                setModalOpen(true);
            }else{
                setImageFile(URL.createObjectURL(file)); // 선택된 파일을 URL.createObjectURL을 사용하여 미리보기로 보여줌
                setImageFileName(file);
            }
        }else{
            setImageFile(null);
        }

    };
    const delStore = async(newToken) => {
        // console.log("delStore");

        try{            
            const ret = await axios.delete(`${Config.API_SERVER}store`,
                                { headers: { 
                                  Authorization: `Bearer ${newToken? newToken : userInfo.jwtToken}`,
                                    },
                                }
                            );

            navigate('/login');

        } catch (error) {
            // 에러 발생 시의 코드
            // console.error("Error fetching data : ", JSON.stringify(error));
            if (error.response) {
              if (error.response.data.resultCode === 401) {
                const autologin = localStorage.getItem("autologin");
                console.log(autologin);
                if(autologin){
                  refetchJwt("DEL_DATA");                
                }else{
                modalInfo.actionFlag = 'login';
                modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
                setModalOpen(true);
                }
              }
            } else if (error.request) {
                // 요청은 전송되었지만 응답이 없는 경우
                // alert("No response received from the server.");
                modalInfo.actionFlag = 'api';
                openModal();
            } else {
                // 요청을 설정하는 중에 에러가 발생한 경우
                // alert("Error setting up the request.");
                modalInfo.actionFlag = 'api';
                openModal();
            }
            console.error("Error fetching data:", error);
        }        
    };

    useEffect(() => {
        getData();
        getBankData();
    }, [userInfo]);

    
    return (
      <>
        <AlertModal
          open={modalOpen}
          close={closeModal}
          header={modalInfo.headerMsg}
          isConfirm={modalInfo.isConfirm}
          isMessage={modalInfo.isMessage}
          isCancel={modalInfo.isCancel}
          action={doModal}
        >
          {modalInfo.mainMsg}
        </AlertModal>
  
        <section className="location_box">
          <ul className="location_001">
            <li>{t.home}</li>
            <li>{t.myPage}</li>
            <li>{t.storeManagement}</li>
          </ul>
        </section>
  
        <main>
          <section className="contants_box contants_box_mypage">
            <LeftMenu pageName="store" />
  
            <section className="rightcontant_box">
              <h2 className="tit_01">{t.storeManagement}</h2>
  
              <article className="contants_box_03">
                <table className="member_box">
                  <tbody>
                    <tr>
                      <th>{t.storeId}</th>
                      <td>
                        <input
                          className="input_01"
                          type="text"
                          style={{ backgroundColor: '#ddd' }}
                          disabled
                          value={storeData.storeId || ''}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{t.storeName}</th>
                      <td>
                      <form>
                        <input
                          className="input_01"
                          type="text"
                          maxLength={64}
                          value={storeData.storeName || ''}
                          onChange={(e) => setStoreData({ ...storeData, storeName: e.target.value })}
                        />
                            </form>
                      </td>
                    </tr>
                    <tr>
                                    <th>{t.storeLogo}</th>
                                    <td>
                                        <div className="signup_06">
                                            <div className="signup_06_002">
                                               
                                                {imageFile && <img className="signup_img_xbtn" src={imageFile} alt="Selected"/>}
                                            
                                            </div>
                                            <div>
                                                <form>
                                                    <input type="file" name="fileInput" onChange={changeFile} accept=".jpg, .jpeg, .gif, .png" />
                                                </form>
                                                <p className="font_gray">{t.uploadFileNote}</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                    <tr>
                      <th>{t.phoneNumber}</th>
                      <td>
                      <form>
                        <input
                          className="input_02"
                          type="text"
                          maxLength={3}
                          value={sms1}
                          onChange={(e) => setSms1(e.target.value)}
                        />
                        -
                        <input
                          className="input_02"
                          type="text"
                          maxLength={4}
                          value={sms2}
                          onChange={(e) => setSms2(e.target.value)}
                        />
                        -
                        <input
                          className="input_02"
                          type="text"
                          maxLength={4}
                          value={sms3}
                          onChange={(e) => setSms3(e.target.value)}
                        />
                        <label className="check_box" style={{marginLeft: '30px'}}>
                          {t.phoneSameAsAdmin}
                          <input
                            type="checkbox"
                            checked={isPhoneChecked}
                            onChange={() => setIsPhoneChecked(!isPhoneChecked)}
                          />
                          <span className="check_check"></span>
                        </label>
                        </form>
                      </td>
                    </tr>
                    <tr>
                      <th>{t.address}</th>
                      <td>
                        <div className="signup_04">
                        <form>
                          <input
                            className="input_01_01"
                            style={{ backgroundColor: '#ddd' }}
                            type="text"
                            id="postcode"
                            disabled
                            placeholder={t.zipCode}
                            value={zipCode}
                          />
                          <button type="button" className="button_05 button_gray" onClick={getPostcode}>
                            {t.search}
                          </button>
                          <label className="check_box" style={{ marginLeft: '30px' }}>
                            {t.phoneSameAsAdmin}
                            <input
                              type="checkbox"
                              checked={isAddrChecked}
                              onChange={() => setIsAddrChecked(!isAddrChecked)}
                            />
                            <span className="check_check"></span>
                          </label>
                          <br />
                          <input className="input_03" type="text" id="jibunAddress" disabled placeholder="지번주소" hidden={true} />
                          <input className="input_03" type="text" id="roadAddress" disabled placeholder={t.roadAddress} value={addr} />
                          <input
                            className="input_03"
                            type="text"
                            id="detailAddress"
                            maxLength={64}
                            placeholder={t.detailAddress}
                            value={detailAddr}
                            onChange={(e) => setDetailAddr(e.target.value)}
                          />
                          <input className="input_03" type="text" id="extraAddress" disabled placeholder="참고항목"  hidden={true}/>

                           </form>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>{t.selectBank}</th>
                      <td>
                      <form>
                        <select
                          className="input_01"
                          onChange={bankChange}
                          value={storeData.bankCode || ''}
                        >
                          {bankData.map((bank, index) => (
                            <option key={index} value={bank.detailCode}>
                              {bank.detailCodeName}
                            </option>
                          ))}
                        </select>
                        </form>
                      </td>
                    </tr>
                    <tr>
                      <th>{t.bankAccountNumber}</th>
                      <td>
                      <form>
                        <input
                          className="input_01"
                          type="text"
                          placeholder={t.noDashPlaceholder}
                          maxLength={20}
                          value={storeData.accountNo || ''}
                          onChange={(e) => setStoreData({ ...storeData, accountNo: e.target.value })}
                        />
                         </form>
                      </td>
                     
                    </tr>
                    <tr>
                      <th>{t.accountHolder}</th>
                      <td>
                      <form>
                        <input
                          className="input_01"
                          type="text"
                          maxLength={80}
                          value={storeData.accountHolder || ''}
                          onChange={(e) => setStoreData({ ...storeData, accountHolder: e.target.value })}
                        />
                        </form>
                      </td>
                    </tr>
                    <tr>
                      <th>{t.giftQR}</th>
                      <td>
                        <div className="signup_06">
                          <div className="signup_06_002">
                            {storeData.qrValue !== '' && (
                              <QRCode value={`https://shopuser-dev.naegift.com/${storeData.storeNo}`} />
                            )}
                          </div>
                          <div className="button-container">
                          <a 
                href="https://shopuser-dev.naegift.com/64" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <button
                  type="button"
                  className="button_05 button_gray"
                  style={{ width: '300px' }}
                >
                  {t.movetogiftpage}
                </button>
              </a>
                            <button type="button" className="button_05 button_black">
                              {t.downloadQR}
                            </button>
                            <button type="button" className="button_05 button_gray">
                              {t.printQR}
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>{t.registrationDate}</th>
                      <td>
                      <form>
                        <input
                          className="input_01"
                          style={{ backgroundColor: '#ddd' }}
                          type="text"
                          disabled
                          value={regDttm || ''}
                        />
                        </form>
                      </td>
                    </tr>
                    <tr>
                      <th>{t.modificationDate}</th>
                      <td>
                      <form>
                        <input
                          className="input_01"
                          style={{ backgroundColor: '#ddd' }}
                          type="text"
                          disabled
                          value={updDttm || ''}
                        />
                        </form>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </article>
  
              <article className="button_box_01">
                <button
                  type="button"
                  className="button_01 button_03 button_gray_line font_red"
                  onClick={() => {
                    modalInfo.actionFlag = 'del';
                    setModalOpen(true);
                  }}
                >
                  {t.delete}
                </button>
                <NavLink to="/gift-list">
                  <button type="button" className="button_01 button_03 button_gray_line">
                    {t.cancel}
                  </button>
                </NavLink>
                <button
                  type="button"
                  className="button_01 button_03 button_red"
                  onClick={() => modStore()}
                >
                  {t.save}
                </button>
              </article>
            </section>
          </section>
        </main>
      </>
    );
  }