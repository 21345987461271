import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import LeftMenu from '../../components/leftMenu';
import AlertModal from '../../components/alertModal';
import { formatStrNumber, getFormattedDateTime, delComString } from '../../components/utils';
import { useSelector, useDispatch } from "react-redux";
import { Config } from "../../common/config";
import axios from "axios";
import { userLogin } from "../../reducers/userInfo";

export default function GiftContent(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const userInfo = useSelector((state) => state.userInfo);
  
    const [modalOpen, setModalOpen] = useState(false);   //모달 상태 관리 : 기본값 - 닫힘
    const [modalInfo, setModalInfo] = useState({isCancel:false, isConfirm:true, isMessage:true, headerMsg:'', actionFlag:'', mainMsg:'', action:'', isPercent:false, img: null});
    const [giftName, setgiftName] = useState('');
    const [categoryCode, setCategoryCode] = useState('GC01');
    const [categoryData, setCategoryData] = useState([]);
    const [categoryName, setCategoryName] = useState("음식점");
    const [imageFile, setImageFile] = useState(null);
    const [imageFileName, setImageFileName] = useState(null);
    const [giftDesc, setGiftDesc] = useState('');
    const [openAIGiftDescData, setOpenAIGiftDescData] = useState([]);
    const [openAISelectedData, setOpenAISelectedcData] = useState('');
    const [price, setPrice] =  useState('');
    const today = getFormattedDateTime(new Date());
    const [saleStartDttm, setSaleStartDttm] =  useState(today.slice(0, 10));
    const [saleEndDttm, setSaleEndDttm] =  useState('');
    const [saleEndDttmPre, setSaleEndDttmPre] =  useState('');
    const [validityMonth, setValidityMonth] =  useState('');
    const [validityDay, setValidityDay] =  useState('');
    const [validityMonthPre, setValidityMonthPre] =  useState('');
    const [validityDayPre, setValidityDayPre] =  useState('');
    const [countIssue, setCountIssue] =  useState('');
    const [countIssuePre, setCountIssuePre] =  useState('');
    const [transferTimeHour, setTransferTimeHour] =  useState('');
    const [transferTimeDay, setTransferTimeDay] =  useState('');
    const [transferTimeHourPre, setTransferTimeHourPre] =  useState('');
    const [transferTimeDayPre, setTransferTimeDayPre] =  useState('');
    const [isSalePeriodChecked, setIsSalePeriodChecked] = useState(false); 
    const [isValidityChecked, setIsValidityChecked] = useState(true); 
    const [isCountIssueChecked, setIsCountIssueChecked] = useState(false); 
    const [isTranferTimeChecked, setIsTranferTimeChecked] = useState(true); 

    console.log(categoryData)

    const openModal = (e) => {
        // console.log("openModal actionFlag:"+modalInfo.actionFlag);
       if(modalInfo.actionFlag === 'api'){
            setModalInfo({...modalInfo, mainMsg:'API 서버에러입니다. 잠시 후 다시 시도해주세요.'});
        }
            setModalOpen(true);
     };
     
     const closeModal = () => {
        setModalInfo({...modalInfo, actionFlag: '', isCancel: false, isConfirm: true});
        setModalOpen(false);
     };
 console.log(categoryName, categoryCode)
     const doModal = () => {
        // console.log("doModal actionFlag:"+modalInfo.actionFlag);
        setModalOpen(false);
        if(modalInfo.actionFlag === 'goMain'){
            navigate('/go-main');
        }else if(modalInfo.actionFlag === 'openai'){
            modalInfo.actionFlag ='' ;
            // setGiftData({ ...giftData, giftDesc: openAISelectedData }); 
            setGiftDesc(openAISelectedData? openAISelectedData : giftDesc);
       } 

     };
  
    const categoryChange = (e) => {
        setCategoryCode(e.target.value);
        setCategoryName(e.target.selectedOptions[0].innerText)
    };

    const giftDescChange = (e) => {
        setOpenAISelectedcData(e.target.value );
    };

    const salePeriodCheckboxChange = (e) => {
        setIsSalePeriodChecked(!isSalePeriodChecked); 

        if (e.target.checked) {
          setSaleEndDttm('');
        }else{
            setSaleEndDttm(saleEndDttmPre);
        }
    };

    const validyCheckboxChange = (e) => {
        const targetValue = e.target.value;
        const isChecked = e.target.checked;

        // console.log(`Selected value: ${targetValue}`);
        if (isChecked) {
            if (targetValue === 'true') {
                // console.log("true");
                setValidityDay('');
                setValidityMonth(validityMonthPre);
                setIsValidityChecked(true);
            } else {
                // console.log("false");
                setValidityMonth('');
                setValidityDay(validityDayPre);
                setIsValidityChecked(false);
            }
        }
    };

    const countIssueCheckboxChange = (e) => {
        setIsCountIssueChecked(!isCountIssueChecked); 

        if (e.target.checked) {
           setCountIssue('');
        }else{
            setCountIssue(countIssuePre);
        }
    };

    const transferTimeCheckboxChange = (e) => {
        const targetValue = e.target.value;
        const isChecked = e.target.checked;

        // console.log(`Selected value: ${targetValue}`);
        if (isChecked) {
            if (targetValue === 'true') {
                // console.log("true");
                setTransferTimeDay('');
                setTransferTimeHour(transferTimeHourPre);
                setIsTranferTimeChecked(true);
            } else {
                // console.log("false");
                setTransferTimeHour('');
                setTransferTimeDay(transferTimeDayPre);
                setIsTranferTimeChecked(false);
            }
        }
    };

    const refetchJwt = async(refetchmod) => {
    
      try{
      const response = await axios.post(`${Config.API_SERVER}auth/getuser`, {
        userId: userInfo.id,
        pwd:userInfo.pwd
      })
  
      console.log("jwt재발급: ",response.data.data.accessToken)
      const jwtToken = response.data.data.accessToken;
      const user ={ 
        ...userInfo,
        jwtToken: jwtToken
      }
      console.log(user);
      dispatch(userLogin(user));
      if(refetchmod=="REG_DATA"){
        regGift(jwtToken);
      }else{
        getData(jwtToken);
      }
    
    }catch(e){
      console.log(e);
    }
    }

    async function getData(newToken) {   
        try{
            const ret = await axios.get(
                `${Config.API_SERVER}code/detail`+
                "?pageSize=20&sortOrd=asc&groupCodeName=카테고리",
                { headers: { 
                  Authorization: `Bearer ${newToken? newToken : userInfo.jwtToken}`,
                    },
                }
            );
            
            if(ret?.data?.data){
                // const count = ret.data.data.totalCount;
                // setBankData(JSON.stringify(ret.data.data.list));     
                setCategoryData(ret.data.data.list);                
            }
        }catch (error) {
          if (error.response.data.resultCode === 401) {
            const autologin = localStorage.getItem("autologin");
            console.log(autologin);
            if(autologin){
              refetchJwt();                
            }else{
            modalInfo.actionFlag = 'login';
            modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
            setModalOpen(true);
            }
          }
            console.error("Error getData:", error);
        }
    }

    const changeFile = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 3 * 1024 * 1024) { // 3MB 이상인 경우
                e.target.value = null; // 파일 선택 취소
                modalInfo.mainMsg = '이미지 파일 크기는 3MByte를 초과할 수 없습니다.';
                setModalOpen(true);
            }else{
                setImageFile(URL.createObjectURL(file)); // 선택된 파일을 URL.createObjectURL을 사용하여 미리보기로 보여
                setImageFileName(file);
            }
        }else{
            setImageFile(null);
        }
    };
    const changeDesc = async () => {
      console.log(userInfo);
      try {
          if (giftName) {
              const Data = {
                  input_text: `${userInfo.storeName}\\\\${giftName}`
              };
  
              modalInfo.mainMsg = '인공지능이 기프트에 어울리는 다양한 광고문구를 생성중입니다.';
              modalInfo.isCancel = false;
              modalInfo.isConfirm = false;
              setModalInfo({...modalInfo, img: "../images/AI Platform.png" });
  
              openModal();
  
              // 광고 문구 생성 (GPT-4.0)
              const ret = await axios.post(`https://chat-dev2.naegift.com/chat/ad_text`,
                  Data,
                  { headers: { 
                      "Content-Type": "application/json",
                  }}
              );
  
              console.log("Response data:", ret.data);
  
              // 첫 번째 객체의 데이터를 추출하여 사용합니다.
              const getGiftDesc = ret.data.output_text[0];
              console.log(getGiftDesc);
  
              // 첫 번째 객체의 데이터를 줄 단위로 분리하여 배열로 변환합니다.
              const splittedArray = getGiftDesc.split('\n').map(line => {
                  let trimmedLine = line.trim(); 
                  if (trimmedLine === "") return "";
                  trimmedLine = trimmedLine.replace(/^\d+\.\s*/, ''); // 숫자와 점 제거
                  trimmedLine = trimmedLine.replace(/^"|"$/g, ''); // 따옴표 제거
                  return trimmedLine;
              });
  console.log(splittedArray)
              // 빈 문자열을 제거합니다.
              const filteredArray = splittedArray.filter(line => line !== "");
  
              // 5개의 광고 문구만 사용합니다.
              const finalArray = filteredArray.slice(0, 5);
              
              console.log(finalArray); // 콘솔에 최종 결과를 출력합니다.
  
              setOpenAIGiftDescData(finalArray);
              setModalInfo({...modalInfo, actionFlag: 'openai', isCancel: true, isConfirm: true});
              setModalOpen(true);
  
          } else {
              modalInfo.mainMsg = '기프트명을 입력해 주세요.';
              setModalOpen(true);
          }
      } catch (error) {
          // 에러 발생 시의 코드
          setModalInfo({...modalInfo, isCancel: false, isConfirm: true});
          console.error("Error fetching data:", error);
      }
  };

    const checkRegGift = () => {
        // console.log("=======================   checkRegGift");

        if(!giftName)
        {
            modalInfo.mainMsg = '기프트명을 입력해 주세요.';
            return false;
        }
        if(!price)
        {
            modalInfo.mainMsg = '가격을 입력해 주세요.';
            return false;
        }
        if(!validityMonth && !validityDay)
        {
            modalInfo.mainMsg = '유효기간을 입력해 주세요.';
            return false;
        }else {
            if(isValidityChecked){
                if(!validityMonth){
                    modalInfo.mainMsg = '유효기간의 개월수를 입력해 주세요.';
                    return false;
                }
            }else{
                if(!validityDay){
                    modalInfo.mainMsg = '유효기간의 일수를 입력해 주세요.';
                    return false;
                }     
            }
        }            
        if(countIssue === '0'){
            setCountIssue('');
        }
        if(!transferTimeHour && !transferTimeDay)
        {           
            modalInfo.mainMsg = '자동송금시간을 입력해 주세요.';
            return false;
        }else {
            if(isTranferTimeChecked){
                if(!transferTimeHour){
                    modalInfo.mainMsg = '자동송금시간의 시간을 입력해 주세요.';
                    return false;
                }
            }else{
                if(!transferTimeDay){
                    modalInfo.mainMsg = '자동송금시간의 일수를 입력해 주세요.';
                    return false;
                }     
            }
        }

        return true;      
    }

    const regGift = async (newToken) => {
    //  console.log("=======================   regGift");

        try{
            if (checkRegGift() === true) {
                const formData = new FormData();
                formData.append('giftName', giftName);
                formData.append('categoryCode', categoryCode);
                if(giftDesc !== ''){
                    formData.append('giftDesc', giftDesc);
                }
                formData.append('price', delComString(price));
                // formData.append(saleStartDttm, 'saleStartDttm');
                formData.append('saleStartDttm', today.slice(0, 10));
                if(saleEndDttm !== ''){
                    formData.append('saleEndDttm', saleEndDttm);
                }
                if(isValidityChecked){
                    formData.append('validity', validityMonth+'M')
                }else{
                    formData.append('validity', validityDay+"D")
                }
                if(countIssue !== '' && countIssue !== '0'){
                    formData.append('countIssued', countIssue);
                }
                if(isTranferTimeChecked){
                    formData.append('transferTime', transferTimeHour+'H')
                }else{
                    formData.append('transferTime', transferTimeDay+'D')
                }
                if(saleStartDttm === today.slice(0, 10)){
                    formData.append('state', 'SS02')
                }

                // 이미지 파일 추가
                if(imageFile){
                    // console.log("file");
                    formData.append("file", imageFileName);
                }else{
                    // console.log("No file");
                }
                const ret = await axios.post(`${Config.API_SERVER}gift`,
                    formData,
                    { headers: { 
                      Authorization: `Bearer ${newToken? newToken : userInfo.jwtToken}`,
                        },
                    }
                );
                // console.log("Data : "+JSON.stringify(ret.data));

                setModalInfo({...modalInfo, mainMsg: '요청정보를 성공적으로 등록하였습니다.'});
                setModalOpen(true);
            }else{
                setModalInfo({...modalInfo}); 
                setModalOpen(true);
            }  
        } catch (error) {
        // 에러 발생 시의 코드
            // console.error("Error fetching data : ", JSON.stringify(error));
            if (error.response) {
              if (error.response.data.resultCode === 401) {
                const autologin = localStorage.getItem("autologin");
                console.log(autologin);
                if(autologin){
                  refetchJwt("REG_DATA");                
                }else{
                modalInfo.actionFlag = 'login';
                modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
                setModalOpen(true);
                }
              }
            } else if (error.request) {
                // 요청은 전송되었지만 응답이 없는 경우
                // alert("No response received from the server.");
                modalInfo.actionFlag = 'api';
                openModal();
            } else {
                // 요청을 설정하는 중에 에러가 발생한 경우
                // alert("Error setting up the request.");
                modalInfo.actionFlag = 'api';
                openModal();
            }
            console.error("Error fetching data:", error);
        }
    } 

    useEffect(() => {
        getData();

    }, []);

    
    return (
    <>
        <AlertModal open={modalOpen} close={closeModal} action={doModal} header={modalInfo.headerMsg} 
            isConfirm={modalInfo.isConfirm} isMessage={modalInfo.isMessage} isCancel={modalInfo.isCancel} img = {modalInfo.img}>
            {modalInfo.actionFlag === 'openai' ? 
                <>
                    <p>광고문구를 선택해주세요.</p>
                    <br />
                    <form>
                        <select className="input_01" onChange={giftDescChange} value={openAISelectedData  || ''} >
                                {openAIGiftDescData.map((item, index) => (
                                    <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </form>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </>
            :
                <>
                    {modalInfo.mainMsg}

                </>
            }
        </AlertModal>
    

        <section className="location_box">
            <ul className="location_001">
                <li>홈</li>
                <li>마이페이지</li>
                <li>기프트 관리</li>
                <li>기프트 등록</li>
            </ul>
        </section>

        <main>
            <section className="contants_box contants_box_mypage">

                <LeftMenu pageName='gift'/> 

                <section className="rightcontant_box">
                    <h2 className="tit_01">기프트 등록</h2>

                    <article className="contants_box_03">
                        <table className="member_box">
                            <tbody>
                                <tr>
                                    <th>기프트 명</th>
                                    <td>
                                        <form>
                                            <input className="input_03" type="text" name=""  placeholder="" maxLength={64}
                                                onChange={e => {
                                                    setgiftName(e.target.value);
                                                }} 
                                                value = {giftName}                                        
                                            />
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <th>카테고리</th>
                                    <td>
                                        <form>
                                            <select className="input_01" onChange={categoryChange} value= {categoryCode}>
                                                {categoryData.map((category, index) => (
                                                    <option key={index} value= {category.detailCode}>{category.detailCodeName}</option>
                                                ))}
                                            </select>
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <th>기프트 사진</th>
                                    <td>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div>
                                                <form>
                                                    <input type="file" name="fileInput" onChange={changeFile} accept=".jpg, .jpeg, .gif, .png" />
                                                </form>
                                                <p className="font_gray">※ jpg,jpeg,gif,png 파일 첨부 가능, 1개 파일을 3MByte까지 업로드 할 수 있습니다.</p>
                                            </div>
                                            <div style={{width: '100px'}}></div>
                                            <div>
                                                {imageFile && <img src={imageFile} alt="Selected" style={{ maxWidth: '100px', maxHeight: '100px' }} />}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>기프트 광고</th>
                                    <td>
                                        <form>
                                            <input className="input_04" type="text" name=""  placeholder="" maxLength={128}
                                                onChange={e => {
                                                    setGiftDesc(e.target.value);
                                                }} 
                                                value = {giftDesc}                                        
                                            />
                                            <button type="button" className="button_05 button_gray" onClick={changeDesc} >광고문구 생성</button>
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <th>가격</th>
                                    <td>
                                        <form>
                                            <input className="input_01_01" style={{textAlign: 'right'}} type="text" name="" maxLength={11} 
                                                onChange={e => {
                                                        setPrice(formatStrNumber(e.target.value));
                                                    }} 
                                                    value = {price}                                        
                                            />&ensp;&ensp;&ensp;원
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <th>판매기간</th>
                                    <td>
                                        <form>
                                            <input className="input_05" type="date" name=""
                                              onChange={e => {                                                
                                                setSaleStartDttm(e.target.value);
                                                }} 
                                                value = {saleStartDttm}
                                            /> ~ 
                                            <input className="input_05" type="date" name=""
                                                onChange={e => {                                                
                                                    setSaleEndDttm(e.target.value);
                                                    setSaleEndDttmPre(e.target.value);
                                                    }} 
                                                    value = {saleEndDttm}
                                                />
                                            <label className="check_box">무기한
                                                <input type="checkbox" checked={isSalePeriodChecked} onChange={salePeriodCheckboxChange} />
                                                <span className="check_check"></span>
                                            </label>
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <th>유효기간</th>
                                    <td>
                                        구매후&ensp;&ensp;&ensp;&ensp;&ensp;
                                        <label className="check_box">
                                            <input type="checkbox" name="validity" checked={isValidityChecked} value="true" onChange={validyCheckboxChange} />
                                            <span className="check_radio"></span>
                                        </label>
                                        <input className="input_02" type="text" name="" style={{textAlign: 'right'}}  maxLength={2} 
                                            onChange={e => {                                                
                                                setValidityMonth(formatStrNumber(e.target.value));
                                                setValidityMonthPre(formatStrNumber(e.target.value));
                                                }} 
                                                value = {validityMonth}
                                        /> 개월&ensp;&ensp;&ensp;

                                        <label className="check_box">
                                            <input type="checkbox" name="validity" checked={!isValidityChecked}  value="false" onChange={validyCheckboxChange} />
                                            <span className="check_radio"></span>
                                        </label>
                                        <input className="input_02" type="text" name="" style={{textAlign: 'right'}}  maxLength={3}   
                                            onChange={e => {                                                
                                                setValidityDay(formatStrNumber(e.target.value));
                                                setValidityDayPre(formatStrNumber(e.target.value));
                                                }} 
                                                value = {validityDay}
                                        /> 일
                                    </td>
                                </tr>
                                <tr>
                                    <th>총발행수량</th>
                                    <td>
                                        <form>
                                            <input className="input_05" type="text" name="" style={{textAlign: 'right'}}  maxLength={11} 
                                                onChange={e => {                                                
                                                        setCountIssue(formatStrNumber(e.target.value));
                                                        setCountIssuePre(formatStrNumber(e.target.value));
                                                        }} 
                                                        value = {countIssue}
                                            /> 개
                                            <label className="check_box">&ensp;&ensp;&ensp;무제한
                                                <input type="checkbox" checked={isCountIssueChecked} onChange={countIssueCheckboxChange} />
                                                <span className="check_check"></span>
                                            </label>
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <th>자동송금시간</th>
                                    <td>
                                        사용후&ensp;&ensp;&ensp;&ensp;&ensp;
                                        <label className="check_box">
                                            <input type="checkbox" name="transferTime" checked={isTranferTimeChecked} value='true' onChange={transferTimeCheckboxChange} />
                                            <span className="check_radio"></span>
                                        </label>
                                        <input className="input_02" type="text" name="" style={{textAlign: 'right'}}  maxLength={2}   
                                            onChange={e => {                                                
                                                setTransferTimeHour(formatStrNumber(e.target.value));
                                                setTransferTimeHourPre(formatStrNumber(e.target.value));
                                                }} 
                                                value = {transferTimeHour}
                                        /> 시간&ensp;&ensp;&ensp;

                                        <label className="check_box">
                                            <input type="checkbox" name="transferTime" checked={!isTranferTimeChecked} value='false' onChange={transferTimeCheckboxChange} />
                                            <span className="check_radio"></span>
                                        </label>
                                        <input className="input_02" type="text" name="" style={{textAlign: 'right'}}  maxLength={3}   
                                            onChange={e => {                                                
                                                setTransferTimeDay(formatStrNumber(e.target.value));
                                                setTransferTimeDayPre(formatStrNumber(e.target.value));
                                                }} 
                                                value = {transferTimeDay}
                                        /> 일
                                    </td>
                                </tr>
                                <tr>
                                    <th>등록일시</th>
                                    <td>
                                        <form>
                                            <input className="input_01" type="text" name="" disabled style={{backgroundColor:  '#ddd', textAlign: 'center'}} value={today}/>
                                        </form>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </article>

                    <article className="button_box_01">
                        <NavLink to='/gift-list'>
                            <button type="button" className="button_01 button_03 button_gray_line">취소</button>
                        </NavLink>
                        <button type="button" className="button_01 button_03 button_red" 
                            onClick ={regGift}>등록</button>
                    </article>

                </section>

            </section>
        </main>
    </>
    );
}