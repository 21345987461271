import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux'; // redux의 useSelector 임포트
import translations from '../data/translation'; // translations 임포트

export default function LeftMenu(props) {
    const pageName = props.pageName;

    // redux의 language 상태 가져오기
    const language = useSelector((state) => state.language.language);

    // 현재 언어에 따른 번역 객체 가져오기
    const t = translations[language];

    return (
        <>
            <section className="leftmenu_box">
                <h2 className="tit_01">{t.myPage}</h2> {/* 마이페이지 번역 적용 */}
                <nav>
                    <ul className="leftmenu_01">
                        <li>
                            <NavLink className={pageName === 'gift' ? 'select' : ''} to='/gift-list'>
                                {t.giftManagement || '기프트 관리'}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className={pageName === 'user' ? 'select' : ''} to='/user-manage'>
                                {t.userManagement || '관리자 정보 관리'}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className={pageName === 'store' ? 'select' : ''} to='/store-manage'>
                                {t.storeManagement || '상점 정보 관리'}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className={pageName === 'qna' ? 'select' : ''} to='/qna-list'>
                                {t.serviceInquiry || '서비스 문의'}
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </section>
        </>
    );
}