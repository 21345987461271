import { createSlice } from "@reduxjs/toolkit";

const initUserInfo = {
  userNo: '',
  id: '',
  pwd:'',
  jwtToken: '',
  name: '',
  mobile: '',
  zipCode: '',
  addr: '',
  detAddr: '',
  role: '',   // 어드민/관리자/개인
  storeNo: '',
  storeName: '',
  storeImageUrl: '',
};



export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState: { ...initUserInfo },
  reducers: {
    userLogin: (state, action) => {
      return { ...action.payload };
    },
    userLogout: (state) => {
      return { ...initUserInfo };
    },
  },
});

export const { userLogin, userLogout } = userInfoSlice.actions;

export default userInfoSlice.reducer;