import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LeftMenu from '../../components/leftMenu';
import { useSelector, useDispatch } from "react-redux";
import AlertModal from '../../components/alertModal';
import { Config } from "../../common/config";
import axios from "axios";
import {  formatNumNumber, getFormattedDateTime, formatNumber } from '../../components/utils';
import Pagination from '../../components/pagination';
import { userLogin } from "../../reducers/userInfo";
import translations from "../../data/translation";

export default function GiftSales(props) {
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state.userInfo);
    const language = useSelector((state) => state.language.language); // redux에서 언어 상태 가져오기
    const t = translations[language]; // 현재 언어에 따른 번역 객체 가져오기
    const dispatch = useDispatch();
    const location = useLocation();
    const receivedData = location.state;
    // console.log("receivedData : "+JSON.stringify(receivedData));
    const giftId = receivedData.giftId;
    // console.log("giftId : ["+giftId+"]");

    const [modalOpen, setModalOpen] = useState(false); 
    const [modalInfo, setModalInfo] = useState({isCancel:false, isConfirm:true, isMessage:true, headerMsg:'', actionFlag:'', mainMsg:'', action:'', isPercent:false});
    const today = getFormattedDateTime(new Date());
    // console.log("today : "+new Date());
    const [searchCondition, setSearchCondition] = useState({
        flag: false,
        pageSize: Config.PAGE_SIZE_LIST[0], 
        sortOrd:'',
        searchCon: '',
        startDttm: '',
        endDttm: '',  
        conOrd: '', 
        giftId: giftId === 0 ?  '': giftId,
        giftIdOrd: '',
        serial: '', 
        paymentCon: '',  
        usageCon: '', 
        depositCon: '',
        refundCon: '',
        gifter: '',
        giftee: ''
    });
    const [total, setTotal] = useState(0);
    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [mainData, setMainData] = useState([]);
    const [lastData, setLastData] = useState({});
    

    const openModal = (e) => {
            setModalOpen(true);
    };
     
    const closeModal = () => {
         setModalOpen(false);
    };
 
    const doModal = () => {
        setModalOpen(false);
        if(modalInfo.actionFlag === 'login'){
            navigate('/login');
        }
    };
    const refetchJwt = async() => {
    
      try{
      const response = await axios.post(`${Config.API_SERVER}auth/getuser`, {
        userId: userInfo.id,
        pwd:userInfo.pwd
      })
  
      console.log("jwt재발급: ",response.data.data.accessToken)
      const jwtToken = response.data.data.accessToken;
      const user ={ 
        ...userInfo,
        jwtToken: jwtToken
      }
      console.log(user);
      dispatch(userLogin(user));
      getData(jwtToken);
    }catch(e){
      console.log(e);
    }
    }

    async function getData(newToken) {
      console.log(newToken);
  
        // console.log("===== searchCondition.searchSection : "+JSON.stringify(searchCondition));
        try{
            let params = "?storeNo="+userInfo.storeNo;
            // let params = "?storeNo=4";
            params += "&pageNo="+currentPage;
            params += "&pageSize="+searchCondition.pageSize
            if(searchCondition.sortOrd.length > 0){
                params += "&sortOrd="+searchCondition.sortOrd;
            }
            if(searchCondition.searchCon.length > 0){
                params += "&searchCon="+searchCondition.searchCon;
            }
            if(searchCondition.startDttm.length > 0){
                params += "&startDttm="+searchCondition.startDttm;
            }
            if(searchCondition.endDttm.length > 0){
                params += "&endDttm="+searchCondition.endDttm;
            }
            if(searchCondition.conOrd.length > 0){
                params += "&conOrd="+searchCondition.conOrd;
            }
            if(searchCondition.giftId.length > 0){
                params += "&giftId="+searchCondition.giftId;
            }
            if(searchCondition.giftIdOrd.length > 0){
                params += "&giftIdOrd="+searchCondition.giftIdOrd;
            }
            if(searchCondition.serial.length > 0){
                params += "&serial="+searchCondition.serial;
            }
            if(searchCondition.paymentCon.length > 0){
                params += "&paymentCon="+searchCondition.paymentCon;
            }
            if(searchCondition.usageCon.length > 0){
                params += "&usageCon="+searchCondition.usageCon;
            }
            if(searchCondition.depositCon.length > 0){
                params += "&depositCon="+searchCondition.depositCon;
            }
            if(searchCondition.refundCon.length > 0){
                params += "&refundCon="+searchCondition.refundCon;
            }
            if(searchCondition.gifter.length > 0){
                params += "&gifter="+searchCondition.gifter;
            }
            if(searchCondition.giftee.length > 0){
                params += "&giftee="+searchCondition.giftee;
            }
            console.log("params : "+params);

            const ret = await axios.get(
                Config.API_SERVER+"gift/salesStatus"+
                params,
                { headers: { 
                    Authorization: `Bearer ${newToken ? newToken : userInfo.jwtToken}`,
                    },
                }
            );
            
            if(ret?.data?.data){
                const count = ret.data.data.totalCount;
                // console.log("Count : "+count);
                const tp = Math.ceil(count / searchCondition.pageSize);
                // console.log("tp : "+tp);

                setTotalPage(tp);
                setTotal(count);
                // console.log("List : "+JSON.stringify(ret.data.data.boardList));
                setData(ret.data.data.list);
                setMainData(ret.data.data.list.slice(0, -1));
                setLastData(ret.data.data.list[ret.data.data.list.length - 1]);
            }
            setSearchCondition({...searchCondition, flag: false});
        }catch (error) {
              if (error.response.data.resultCode === 401) {
                const autologin = localStorage.getItem("autologin");
                console.log(autologin);
                if(autologin){
                  refetchJwt();                
                }else{
                modalInfo.actionFlag = 'login';
                modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
                setModalOpen(true);
                }
              }
            console.error("Error getData:", error);
        }
    }

    const pageChange = (page) => {
        // console.log(`Page changed to ${page}`);
        setCurrentPage(page);        
    };

    const getSearch = () => {
        // console.log(`Page changed to ${page}`);
        setSearchCondition({...searchCondition, flag: true});
    };

    const getClear = () => {
        setSearchCondition({...searchCondition, 
            sortOrd:'',
            searchCon: '',
            startDttm: '',
            endDttm: '',  
            conOrd: '', 
            giftId: '',
            giftIdOrd: '',
            serial: '', 
            paymentCon: '',  
            usageCon: '', 
            depositCon: '',
            refundCon: '',
            gifter: '',
            giftee: ''
        });
    }

    useEffect(() => {
        getData();

        return() => {
        // console.log("====== CHARGING UNMOUNT=========");
        }
    }, [currentPage, searchCondition.flag]);

 
    return (
      <>
        <AlertModal
          open={modalOpen}
          close={closeModal}
          action={doModal}
          header={modalInfo.headerMsg}
          isConfirm={modalInfo.isConfirm}
          isMessage={modalInfo.isMessage}
          isCancel={modalInfo.isCancel}
        >
          {modalInfo.mainMsg}
        </AlertModal>
    
        <section className="location_box">
          <ul className="location_001">
            <li>{t.home}</li>
            <li>{t.myPage}</li>
            <li>{t.giftManagement}</li>
            <li>{t.salesStatus}</li>
          </ul>
        </section>
    
        <main>
          <section className="contants_box contants_box_mypage">
    
            <LeftMenu pageName="gift" />
    
            <section className="rightcontant_box">
              <h2 className="tit_01">{t.salesStatus}</h2>
    
              <article className="table_search_box">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <form>
                          <select
                            className="search_input_02"
                            value={searchCondition.searchCon}
                            onChange={(event) =>
                              setSearchCondition({
                                ...searchCondition,
                                searchCon: event.target.value,
                              })
                            }
                          >
                            <option value="" selected={searchCondition.searchCon === ''}>{t.searchCondition}</option>
                            <option value="C1" selected={searchCondition.searchCon === 'C1'}>{t.paymentDate}</option>
                            <option value="C2" selected={searchCondition.searchCon === 'C2'}>{t.usageDate}</option>
                            <option value="C3" selected={searchCondition.searchCon === 'C3'}>{t.depositDate}</option>
                            <option value="C4" selected={searchCondition.searchCon === 'C4'}>{t.refundRequestDate}</option>
                            <option value="C5" selected={searchCondition.searchCon === 'C5'}>{t.refundCompletionDate}</option>
                          </select>
                        </form>
                      </td>
                      <td>
                        <form>
                          {t.searchPeriod}&ensp;
                          <input
                            className="search_input_01"
                            type="date"
                            name=""
                            value={searchCondition.startDttm}
                            onChange={(event) =>
                              setSearchCondition({
                                ...searchCondition,
                                startDttm: event.target.value,
                              })
                            }
                          />
                          &nbsp;~&nbsp;
                          <input
                            className="search_input_01"
                            type="date"
                            name=""
                            value={searchCondition.endDttm}
                            onChange={(event) =>
                              setSearchCondition({
                                ...searchCondition,
                                endDttm: event.target.value,
                              })
                            }
                          />
                        </form>
                      </td>
                      <td>
                        <form>
                          <select
                            className="search_input_03"
                            value={searchCondition.conOrd}
                            onChange={(event) =>
                              setSearchCondition({
                                ...searchCondition,
                                conOrd: event.target.value,
                              })
                            }
                          >
                            <option value="" selected={searchCondition.conOrd === ''}>{t.searchOrder}</option>
                            <option value="desc" selected={searchCondition.conOrd === 'desc'}>{t.descendingOrder}</option>
                            <option value="asc" selected={searchCondition.conOrd === 'asc'}>{t.ascendingOrder}</option>
                          </select>
                        </form>
                      </td>
                      <td>
                        <form>
                          <select
                            className="search_input_03"
                            value={searchCondition.giftIdOrd}
                            onChange={(event) =>
                              setSearchCondition({
                                ...searchCondition,
                                giftIdOrd: event.target.value,
                              })
                            }
                          >
                            <option value="" selected={searchCondition.giftIdOrd === ''}>{t.giftId}</option>
                            <option value="desc" selected={searchCondition.giftIdOrd === 'desc'}>{t.descendingOrder}</option>
                            <option value="asc" selected={searchCondition.giftIdOrd === 'asc'}>{t.ascendingOrder}</option>
                          </select>
                        </form>
                      </td>
                      <td>
                        <form>
                          <select
                            className="search_input_03"
                            value={searchCondition.paymentCon}
                            onChange={(event) =>
                              setSearchCondition({
                                ...searchCondition,
                                paymentCon: event.target.value,
                              })
                            }
                          >
                            <option value="" selected={searchCondition.paymentCon === ''}>{t.paymentInfo}</option>
                            <option value="PM01" selected={searchCondition.paymentCon === 'PM01'}>{t.creditCard}</option>
                            <option value="PM02" selected={searchCondition.paymentCon === 'PM02'}>{t.bankTransfer}</option>
                          </select>
                        </form>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <form>
                          <select
                            className="search_input_02"
                            value={searchCondition.usageCon}
                            onChange={(event) =>
                              setSearchCondition({
                                ...searchCondition,
                                usageCon: event.target.value,
                              })
                            }
                          >
                            <option value="" selected={searchCondition.usageCon === ''}>{t.usageInfo}</option>
                            <option value="GS01" selected={searchCondition.usageCon === 'GS01'}>{t.unused}</option>
                            <option value="GS02" selected={searchCondition.usageCon === 'GS02'}>{t.completed}</option>
                            <option value="GS03" selected={searchCondition.usageCon === 'GS03'}>{t.errorUsed}</option>
                            <option value="GS04" selected={searchCondition.usageCon === 'GS04'}>{t.expired}</option>
                          </select>
                        </form>
                      </td>
                      <td>
                        <form>
                          <select
                            className="search_input_02"
                            value={searchCondition.depositCon}
                            onChange={(event) =>
                              setSearchCondition({
                                ...searchCondition,
                                depositCon: event.target.value,
                              })
                            }
                          >
                            <option value="" selected={searchCondition.depositCon === ''}>{t.depositInfo}</option>
                            <option value="DS01" selected={searchCondition.depositCon === 'DS01'}>{t.notDeposited}</option>
                            <option value="DS02" selected={searchCondition.depositCon === 'DS02'}>{t.deposited}</option>
                          </select>
                        </form>
                      </td>
                      <td>
                        <form>
                          <select
                            className="search_input_01"
                            value={searchCondition.refundCon}
                            onChange={(event) =>
                              setSearchCondition({
                                ...searchCondition,
                                refundCon: event.target.value,
                              })
                            }
                          >
                            <option value="" selected={searchCondition.refundCon === ''}>{t.refundInfo}</option>
                            <option value="RS01" selected={searchCondition.refundCon === 'RS01'}>{t.refundRequest}</option>
                            <option value="RS02" selected={searchCondition.refundCon === 'RS02'}>{t.refundRequestCancel}</option>
                            <option value="RS03" selected={searchCondition.refundCon === 'RS03'}>{t.refundCompleted}</option>
                          </select>
                        </form>
                      </td>
                      <td>
                        <form>
                          <input
                            className="search_input_03"
                            type="text"
                            name=""
                            placeholder={t.giftSerial}
                            maxLength={80}
                            value={searchCondition.serial}
                            onChange={(event) =>
                              setSearchCondition({
                                ...searchCondition,
                                serial: event.target.value,
                              })
                            }
                          />
                        </form>
                      </td>
                      <td>
                        <form>
                          <input
                            className="search_input_03"
                            type="text"
                            name=""
                            placeholder={t.gifter}
                            maxLength={20}
                            value={searchCondition.gifter}
                            onChange={(event) =>
                              setSearchCondition({
                                ...searchCondition,
                                gifter: event.target.value,
                              })
                            }
                          />
                        </form>
                      </td>
                      <td>
                        <form>
                          <input
                            className="search_input_03"
                            type="text"
                            name=""
                            placeholder={t.giftee}
                            maxLength={20}
                            value={searchCondition.giftee}
                            onChange={(event) =>
                              setSearchCondition({
                                ...searchCondition,
                                giftee: event.target.value,
                              })
                            }
                          />
                        </form>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </article>
    
              <article className="button_box_01 serch_btn_01">
                <button type="button" className="button_05 button_black" onClick={getData}>{t.search}</button>
                <button type="button" className="button_05 button_gray_line" onClick={getClear}>{t.reset}</button>
              </article>
    
              <table className="table_box_01">
                <colgroup>
                  <col width="55px" />
                  <col width="210px" />
                  <col width="145px" />
                  <col width="145px" />
                  <col width="145px" />
                  <col width="90px" />
                  <col width="150px" />
                  <col width="170px" />
                </colgroup>
    
                <thead>
                  <tr>
                    <th>{t.no}</th>
                    <th>{t.giftSerial}</th>
                    <th>{t.gifter}</th>
                    <th>{t.giftee}</th>
                    <th>{t.paymentDetails}</th>
                    <th>{t.usageDetails}</th>
                    <th>{t.depositDetails}</th>
                    <th>{t.refundDetails}</th>
                  </tr>
                </thead>
    
                <tbody>
                  {data.length > 1 &&
                    data.map((item, index) =>
                      index < data.length - 1 ? (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.giftId && item.seqNo ? `${item.giftId}-${item.seqNo}` : null}</td>
                          <td>
                            {item.gifterMobile
                              ? `${item.gifterMobile}\n${item.gifterEmail}`
                              : null}
                          </td>
                          <td>
                            {item.gifteeMobile
                              ? `${item.gifteeMobile}\n${item.gifteeEmail}`
                              : null}
                          </td>
                          <td>
                            {item.payMethodDesc}
                            <br />
                            {item.payMethod
                              ? `(${item.paymentDttm.slice(0, 10)}, ${formatNumNumber(
                                  item.price
                                )}${t.currency})`
                              : null}
                          </td>
                          {item.usageState ? (
                            <>
                              {item.usageState === 'GS01' ? (
                                <td>{item.usageStateDesc}</td>
                              ) : (
                                <>
                                  {item.usageState === 'GS02' ? (
                                    <td>
                                     Used
                                     
                                    </td>
                                  ) : (
                                    <td>
                                      {t.errorUsed}({item.usageDttm}, {item.usageStateDesc})
                                    </td>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <td>{t.unused}</td>
                          )}
                          {item.depositState && (
                            <>
                              {item.depositState === 'DS01' ? (
                                <td>{item.depositStateDesc}(예치중)</td>
                              ) : (
                                <td>
                                  {item.depositStateDesc}(
                                  {item.depositDttm}, {formatNumNumber(item.depositAmount)}
                                  )
                                </td>
                              )}
                            </>
                          )}
                          {item.refundState ? (
                            <td>
                              {item.refundStateDesc ? item.refundStateDesc : null}
                              <br />
                              (
                              {item.refundDttm ? item.refundDttm : null},{' '}
                              {item.bankName ? item.bankName : null} /<br />
                              {item.accountNo ? item.accountNo : null} /{' '}
                              {item.accountHolder ? item.accountHolder : null})
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      ) : null
                    )}
                </tbody>
              </table>
    
              <article className="table_search_box">
                <ul className="table_02">
                  <li>{t.totalCount} : {formatNumNumber(lastData.count)}{t.count}</li>
                  <li>{t.paymentAmount} : {formatNumNumber(lastData.totalPayAmount)}{t.currency}</li>
                  <li>{t.usageAmount} : {formatNumNumber(lastData.totalUsageAmount)}{t.currency}</li>
                  <li>{t.depositAmount} : {formatNumNumber(lastData.totalDepositAmount)}{t.currency}</li>
                  <li>{t.reservedAmount} : {formatNumNumber(parseInt(formatNumber(lastData.totalPayAmount)) - parseInt(formatNumber(lastData.totalDepositAmount)) )}{t.currency}</li>
                  <li>{t.refundAmount} : {formatNumNumber(lastData.totalRefumdAmount)}{t.currency}</li>
                  <li>{t.serviceFee} : {formatNumNumber(parseInt(formatNumber(lastData.totalDepositfee)) + parseInt(formatNumber(lastData.totalRefundfee)) )}{t.currency}</li>
                </ul>
              </article>
    
              <ul className="page_box">
                <Pagination totalPage={totalPage} onPageChange={pageChange} />
              </ul>
    
            </section>
    
          </section>
        </main>
      </>
    );
  }