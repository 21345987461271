import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import LeftMenu from '../../components/leftMenu';
import { useSelector, useDispatch } from "react-redux";
import AlertModal from '../../components/alertModal';
import { Config } from "../../common/config";
import axios from "axios";
import { formatStrNumber, formatNumNumber, getFormattedDateTime } from '../../components/utils';
import Pagination from '../../components/pagination';
import { userLogin } from "../../reducers/userInfo";
import translations from "../../data/translation";
import DatePicker, { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko'; // 한국어 로케일 가져오기
import en from 'date-fns/locale/en-US'; // 영어 로케일 가져오기
import "react-datepicker/dist/react-datepicker.css";


export default function GiftList(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state.userInfo);
    const language = useSelector((state) => state.language.language); // redux에서 언어 상태 가져오기
    const t = translations[language]; // 현재 언어에 따른 번역 객체 가져오기
    registerLocale('ko', ko);
    registerLocale('en', en);

    const datePickerStyles = {
      popper: {
          zIndex: 1000
      },
      parentContainer: {
          overflow: 'visible'
      },
      input: {
          padding: '8px',
          margin: 0
      }
  };

    const [modalOpen, setModalOpen] = useState(false);   
    const [modalInfo, setModalInfo] = useState({isCancel:false, isConfirm:true, isMessage:true, headerMsg:'', actionFlag:'', mainMsg:'', action:'', isPercent:false});
    const today = getFormattedDateTime(new Date());
    // console.log("today : "+new Date());
    const [searchCondition, setSearchCondition] = useState({
        flag: false,
        pageSize: Config.PAGE_SIZE_LIST[0], 
        sortOrd:'',
        // startDttm: today.slice(0, 10), 
        // endDttm: today.slice(0, 10),  
        startDttm: '', 
        endDttm: '',  
        giftIdOrd: '', 
        saleOrd: '',
        state: ''
    });
    const [total, setTotal] = useState(0);
    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);

    const openModal = (e) => {
            setModalOpen(true);
    };
     
    const closeModal = () => {
         setModalOpen(false);
    };
 
    const doModal = () => {
        setModalOpen(false);
        if(modalInfo.actionFlag === 'goMain'){
            navigate('/go-main');
        } 
    };

    const modGift = (giftNo) => {
        const passData = {
            giftNo: giftNo,
        };
        // console.log("passData", JSON.stringify(passData));
        if(giftNo === 0){
            // console.log("giftNo : 0");
            navigate('/gift-content');
        }else{ 
            // console.log("giftNo : > 0");
            navigate('/gift-manage', {state: passData});
        }
     };

     const sales = (giftId) => {
        const passData = {
            giftId: giftId,
        };
        // console.log("passData", JSON.stringify(passData));
        navigate('/gift-sales', {state: passData});
     };

     const refetchJwt = async() => {
    
      try{
      const response = await axios.post(`${Config.API_SERVER}auth/getuser`, {
        userId: userInfo.id,
        pwd:userInfo.pwd
      })
  
      console.log("jwt재발급: ",response.data.data.accessToken)
      const jwtToken = response.data.data.accessToken;
      const user ={ 
        ...userInfo,
        jwtToken: jwtToken
      }
      console.log(user);
      dispatch(userLogin(user));
      getData(jwtToken);
    }catch(e){
      console.log(e);
    }
    }

    async function getData(newToken) {
        // console.log("===== searchCondition.searchSection : "+JSON.stringify(searchCondition));
        try{
            let params = "?storeNo="+userInfo.storeNo;
            // let params = "?storeNo=4";
            params += "&historyYn=Y";
            params += "&pageNo="+currentPage;
            params += "&pageSize="+searchCondition.pageSize
            if(searchCondition.sortOrd.length > 0){
                params += "&sortOrd="+searchCondition.sortOrd;
            }
            if(searchCondition.startDttm.length > 0){
                params += "&startDttm="+searchCondition.startDttm;
            }
            if(searchCondition.endDttm.length > 0){
                params += "&endDttm="+searchCondition.endDttm;
            }
            if(searchCondition.giftIdOrd.length > 0){
                params += "&giftIdOrd="+searchCondition.giftIdOrd;
            }
            if(searchCondition.saleOrd.length > 0){
                params += "&saleOrd="+searchCondition.saleOrd;
            }
            if(searchCondition.state.length > 0){
                params += "&state="+searchCondition.state;
            }
            // console.log("params : "+params);

            const ret = await axios.get(
                Config.API_SERVER+"gift"+
                params,
                { headers: { 
                    Authorization: `Bearer ${newToken? newToken : userInfo.jwtToken}`,
                    },
                }
            );
            
            if(ret?.data?.data){
                const count = ret.data.data.totalCount;
                // console.log("Count : "+count);
                const tp = Math.ceil(count / searchCondition.pageSize);
                // console.log("tp : "+tp);

                setTotalPage(tp);
                setTotal(count);
                // console.log("List : "+JSON.stringify(ret.data.data.boardList));
                setData(ret.data.data.list);
            }
            setSearchCondition({...searchCondition, flag: false});
        }catch (error) {
          console.log("로그인 오류 재발급 실행")
            if (error.response.data.resultCode === 401) {
              const autologin = localStorage.getItem("autologin");
              console.log(autologin);
              if(autologin){
                refetchJwt();                
              }else{
              modalInfo.actionFlag = 'login';
              modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
              setModalOpen(true);
              }
            }
            console.error("Error getData:", error);
        }
    }

    const pageChange = (page) => {
        // console.log(`Page changed to ${page}`);
        setCurrentPage(page);        
    };

    const getSearch = () => {
        // console.log(`Page changed to ${page}`);
        setSearchCondition({...searchCondition, flag: true});
    };

    const getClear = () => {
        setSearchCondition({...searchCondition, 
            sortOrd:'',
            // startDttm: today.slice(0, 10), 
            // endDttm: today.slice(0, 10),  
            startDttm: '', 
            endDttm: '',  
            giftIdOrd: '', 
            saleOrd: '',
            state: ''
        });
    }

    useEffect(() => {
      if(userInfo.id){
        getData();
      }
    }, [userInfo,currentPage, searchCondition.flag]);


     return (
        <>
            <AlertModal open={modalOpen} close={closeModal} action={doModal} header={modalInfo.headerMsg} 
                isConfirm={modalInfo.isConfirm} isMessage={modalInfo.isMessage} isCancel={modalInfo.isCancel}>
                {modalInfo.mainMsg}
            </AlertModal>

            <section className="location_box">
                <ul className="location_001">
                    <li>{t.home}</li>
                    <li>{t.myPage}</li>
                    <li>{t.giftManagement}</li>
                </ul>
            </section>

            <main>
                <section className="contants_box contants_box_mypage">
                    <LeftMenu pageName='gift' />

                    <section className="rightcontant_box">
                        <h2 className="tit_01">{t.giftManagement}</h2>

                        <article className="table_search_box">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <form>
                                                {t.searchPeriod}&ensp;
                                                <DatePicker
        selected={searchCondition.startDttm ? new Date(searchCondition.startDttm) : null}
        onChange={(date) => setSearchCondition({ ...searchCondition, startDttm: date })}
        locale={language === 'ko' ? 'ko' : 'en'}
        dateFormat="yyyy-MM-dd" // 날짜 형식 설정
        className="search_input_01"
        placeholderText={language === 'ko' ? '시작 날짜' : 'Start date'}
      />
      &nbsp;~&nbsp;
      <DatePicker
        selected={searchCondition.endDttm ? new Date(searchCondition.endDttm) : null}
        onChange={(date) => setSearchCondition({ ...searchCondition, endDttm: date })}
        locale={language === 'ko' ? 'ko' : 'en'}
        dateFormat="yyyy-MM-dd"
        className="search_input_01"
        placeholderText={language === 'ko' ? '종료 날짜' : 'End date'}
      />
                                            </form>
                                        </td>
                                        <td>
                                            <form>
                                                <select className="search_input_02" value={searchCondition.giftIdOrd}  
                                                    onChange={(event) => setSearchCondition({ ...searchCondition, giftIdOrd: event.target.value })}>
                                                    <option value="" selected={searchCondition.giftIdOrd === ''}>{t.giftId}</option>
                                                    <option value="desc" selected={searchCondition.giftIdOrd === 'desc'}>{t.descendingOrder}</option>
                                                    <option value="asc" selected={searchCondition.giftIdOrd === 'asc'}>{t.ascendingOrder}</option>
                                                </select>
                                            </form>
                                        </td>
                                        <td>
                                            <form>
                                                <select className="search_input_01" value={searchCondition.saleOrd}  
                                                    onChange={(event) => setSearchCondition({ ...searchCondition, saleOrd: event.target.value })}>
                                                    <option value="" selected={searchCondition.saleOrd === ''}>{t.saleQuantity}</option>
                                                    <option value="desc" selected={searchCondition.saleOrd === 'desc'}>{t.descendingOrder}</option>
                                                    <option value="asc" selected={searchCondition.saleOrd === 'asc'}>{t.ascendingOrder}</option>
                                                </select>
                                            </form>
                                        </td>
                                        <td>
                                            <form>
                                                <select className="search_input_01" value={searchCondition.state} 
                                                    onChange={(event) => setSearchCondition({ ...searchCondition, state: event.target.value })}>
                                                    <option value="" selected={searchCondition.state === ''}>{t.state}</option>
                                                    <option value="SS01" selected={searchCondition.state === 'SS01'}>{t.beforeSale}</option>
                                                    <option value="SS02" selected={searchCondition.state === 'SS02'}>{t.inSale}</option>
                                                    <option value="SS04" selected={searchCondition.state === 'SS04'}>{t.endSale}</option>
                                                </select>
                                            </form>
                                        </td>
                                        <td>
                                            <form>
                                                <select className="search_input_01" value={searchCondition.sortOrd}  
                                                    onChange={(event) => setSearchCondition({ ...searchCondition, sortOrd: event.target.value })}>
                                                    <option value="" selected={searchCondition.sortOrd === ''}>{t.registrationDate}</option>
                                                    <option value="desc" selected={searchCondition.sortOrd === 'desc'}>{t.descendingOrder}</option>
                                                    <option value="asc" selected={searchCondition.sortOrd === 'asc'}>{t.ascendingOrder}</option>
                                                </select>
                                            </form>
                                        </td>
                                        <td>
                                            <button type="button" className="button_05 button_black" onClick={getSearch}>{t.search}</button>
                                            <button type="button" className="button_05 button_gray_line" onClick={getClear}>{t.reset}</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </article>
                    
                        <table className="table_box_01">
                            <colgroup>
                                <col width="60px" />
                                <col width="220px" />
                                <col width="100px" />
                                <col width="120px" />
                                <col width="100px" />
                                <col width="140px" />
                                <col width="100px" />
                                <col width="130px" />
                                <col width="175px" />
                            </colgroup>

                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>{t.giftId}</th>
                                    <th>{t.giftImage}</th>
                                    <th>{t.price}</th>
                                    <th>{t.totalSales}/{t.totalIssued}</th>
                                    <th>{t.totalSalesAmount}</th>
                                    <th>{t.state}</th>
                                    <th>{t.registrationDate}</th>
                                    <th>{t.editOrSalesStatus}</th>
                                </tr>
                            </thead>

                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.giftId}</td>
                                        <td><img src={item.thumbnailFileUrl === Config.API_SERVER ? '' : item.thumbnailFileUrl} /></td>
                                        <td>{formatNumNumber(item.price)}{t.currency}</td>
                                        <td>{formatNumNumber(item.countSold)}/
                                            {item.countIssued === null ? '' : formatNumNumber(item.countIssued)}
                                        </td>
                                        <td>{formatNumNumber(item.countSold * item.price)}{t.currency}</td>
                                        <td>{t.stateDesc[item.stateDesc] || item.stateDesc}</td> {/* 상태 번역 적용 */}
                                        <td>{new Date(item.regDttm).toISOString().substring(0, 10)}</td>
                                        <td>
                                            <button type="button" className="button_04 button_gray" 
                                                onClick={(e) => { modGift(item.giftNo) }}>{t.edit}</button>
                                            <button type="button" className="button_04 button_gray_line" 
                                                onClick={(e) => { sales(item.giftId) }}>{t.status}</button>
                                        </td>
                                    </tr>
                                ))}                            
                            </tbody>

                        </table>

                        <p className="table_01">{t.totalCount} : {total} {t.count}</p>

                        <ul className="page_box">
                            <Pagination
                                totalPage={totalPage} onPageChange={pageChange} 
                            /> 
                        </ul>                                           

                        <article className="button_box_02">
                            <button type="button" className="button_01 button_03 button_black_line"
                                onClick={(e) => { sales(0) }}>{t.salesStatus}</button>
                            <button type="button" className="button_01 button_03 button_red" 
                                onClick={(e) => { modGift(0) }}>{t.registerGift}</button>
                        </article>

                    </section>

                </section>
            </main>
        </>
    );
}