import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { userLogout } from "../reducers/userInfo";
import { setLanguage } from '../reducers/languageSlice'; // Redux 액션 임포트
import naegift_ko_logo from "../images/naegift_ko_logo.png";
import naegift_eng_logo from "../images/naegift_eng_logo.png";

import translations from '../data/translation';

export default function Header() {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo);
    const language = useSelector((state) => state.language.language); // Redux에서 언어 상태 가져오기
    const navigate = useNavigate();
    
    const t = translations[language];

    const logOut = () => {
        dispatch(userLogout());
        localStorage.removeItem('user');
        window.location.href = "https://shopadmin-dev.naegift.com/login";
    };

    // 언어 변경 함수: Redux 상태 업데이트
    const changeLanguage = () => {
        const newLanguage = language === 'ko' ? 'en' : 'ko';
        dispatch(setLanguage(newLanguage)); // Redux 액션 디스패치
    };

    return (
    <>
        <header>
            <section className="header_box">
                <h1>
                    <a href="https://www-dev.naegift.com/">
                        <img className="header_logo" src={language==="en" ? naegift_eng_logo : naegift_ko_logo} alt="내기프트" />
                    </a>
                </h1> 
                <nav>
                    <ul className="header_nav">
                        <li>
                            <a className="select" href="https://www-dev.naegift.com/">{t.serviceIntro}</a>
                        </li>
                        <li>
                            <a href="https://www-dev.naegift.com/">{t.storeSupport}</a>
                        </li>
                    </ul>
                </nav>
                <ul className="header_login">
                   
                    {userInfo?.id ? (
                        <>
                            <li onClick={logOut}>{t.logout}</li>
                            <li><a href="https://shopadmin-dev.naegift.com/gift-list">{t.myPage}</a></li>
                        </>
                    ) : (
                        <>
                            <li><a href="https://shopadmin-dev.naegift.com/login">{t.login}</a></li>
                            <li><a href="https://www-dev.naegift.com/user-signup">{t.signUp}</a></li>
                        </>
                    )}
                     <li onClick={changeLanguage} style={{ cursor: 'pointer', marginRight: '20px' }}>
                        {language === 'ko' ? 'EN' : 'KO'} {/* 언어 토글 버튼 */}
                    </li>
                </ul>
            </section>
        </header>
    </>
    );
}
