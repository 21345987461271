import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import LeftMenu from '../../components/leftMenu';
import AlertModal from '../../components/alertModal';
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Config } from "../../common/config";
import { userLogin } from "../../reducers/userInfo";
import translations from "../../data/translation";


export default function QnaManage() {
  const MAX_TITLE_LENGTH = 80;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [modalOpen, setModalOpen] = useState(false);   //모달 상태 관리 : 기본값 - 닫힘
    const [modalInfo, setModalInfo] = useState({isCancel:true, isConfirm:true, isMessage:true, headerMsg:'', actionFlag:'', mainMsg:'등록하시겠습니까?', action:'', isPercent:false});
    const [data, setData] = useState([]);
    const [notice, setNotice] = useState('Y');
    const userInfo = useSelector((state) => state.userInfo);
    const language = useSelector((state) => state.language.language); // redux에서 언어 상태 가져오기
    const t = translations[language]; // 현재 언어에 따른 번역 객체 가져오기
    const [formValues, setFormValues] = useState({
      category : 'QA01',
      userNo : userInfo.userNo,
      storeNo :userInfo.storeNo,
      title : '',
      qContent : '',
      aContent : '',
      file: null,
      notice : 'Y'
  });
  const openModal = () => {
    if (!formValues.category || !formValues.title || !formValues.qContent) {
        // 필수 입력값이 없는 경우 모달창을 열고 반환
        setModalInfo({
            ...modalInfo,
            headerMsg: '알림',
            mainMsg: '모든 필수 입력값을 입력해주세요.',
            actionFlag: "validate",
        });
        setModalOpen(true);
        return;
      }
      setModalOpen(true);
  }
  const closeModal = () => {
  setModalOpen(false);
};
const doModal = () => {
  handleQnaSubmit();
};

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
        ...formValues,
        [name]: value
    });
};
const handleFileChange = (event) => {
  setFormValues({
      ...formValues,
      file: event.target.files[0]
  });
}
const formData = new FormData();
formData.append('qnaCategory', formValues.category);
formData.append('userNo', formValues.userNo);
formData.append('storeNo', formValues.storeNo);
formData.append('title', formValues.title);
formData.append('qContent', formValues.qContent);
if (formValues.file) {
  formData.append('file', formValues.file);
}
formData.append('noticeYn', formValues.notice);

  
const refetchJwt = async() => {
    
  try{
  const response = await axios.post(`${Config.API_SERVER}auth/getuser`, {
    userId: userInfo.id,
    pwd:userInfo.pwd
  })

  console.log("jwt재발급: ",response.data.data.accessToken)
  const jwtToken = response.data.data.accessToken;
  const user ={ 
    ...userInfo,
    jwtToken: jwtToken
  }
  console.log(user);
  dispatch(userLogin(user));
  handleQnaSubmit(jwtToken);
}catch(e){
  console.log(e);
}
}

const handleQnaSubmit = async (newToken) => {

  try {
      const response = await axios.post(
          `${Config.API_SERVER}qna`, // 여기에 API 엔드포인트 URL을 입력하세요
          formData, // 요청 본문에 formValues 추가
          {
            headers: { 
              Authorization: `Bearer ${newToken? newToken : userInfo.jwtToken}`,
              },
          }
      );

      // 성공적으로 등록되었을 때의 작업
      console.log('등록 성공:', response.data);
      setModalInfo({
          ...modalInfo,
          isConfirm: false,
          isMessage: true,
          actionFlag: '',
          headerMsg: '등록',
          mainMsg: '등록 되었습니다'
      });
      navigate('/qna-list');
  } catch (error) {
    if (error.response.data.resultCode === 401) {
      const autologin = localStorage.getItem("autologin");
      console.log(autologin);
      if(autologin){
        refetchJwt();                
      }else{
      modalInfo.actionFlag = 'login';
      modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
      setModalOpen(true);
      }
    }     
      console.error('등록 오류:', error);
  }
};

    useEffect(() => {
      
    }, []);
    console.log(formValues)
    return (
      <>
          <AlertModal open={modalOpen} close={closeModal} action={doModal} header={modalInfo.headerMsg} 
              isConfirm={modalInfo.isConfirm} isMessage={modalInfo.isMessage} isCancel={modalInfo.isCancel}>
              {modalInfo.mainMsg}
          </AlertModal>
          <section className="location_box">
              <ul className="location_001">
                  <li>{t.home}</li>
                  <li>{t.myPage}</li>
                  <li>{t.serviceInquiry}</li>
              </ul>
          </section>
  
          <main>
              <section className="contants_box contants_box_mypage">
  
                  <LeftMenu pageName='qna'/> 
  
                  <section className="rightcontant_box">
                      <h2 className="tit_01">{t.serviceInquiry}</h2>
  
                      <article className="contants_box_03">
                          <table className="member_box">
                              <tbody>
                                  <tr>
                                      <th>{t.category}</th>
                                      <td>
                                          <form>
                                              <select className="input_01" name="category" value={formValues.category} onChange={handleInputChange}>
                                                  <option value="QA01">{t.selectCategory.serviceRelated}</option>
                                                  <option value="QA02">{t.selectCategory.giftIssuance}</option>
                                                  <option value="QA03">{t.selectCategory.giftPurchasePresent}</option>
                                                  <option value="QA04">{t.selectCategory.giftUsageVerification}</option>
                                                  <option value="QA05">{t.selectCategory.deposit}</option>
                                                  <option value="QA06">{t.selectCategory.refund}</option>
                                                  <option value="QA07">{t.selectCategory.others}</option>
                                              </select>
                                          </form>
                                      </td>
                                  </tr>
                                  <tr>
                                      <th>{t.writer} {t.required}</th>
                                      <td>
                                          <form>
                                              <input className="input_01" name="writer" value={userInfo.id} type="text" disabled />
                                          </form>
                                      </td>
                                  </tr>
                                  <tr>
                                      <th>{t.title} {t.required}</th>
                                      <td>
                                          <form className='form_01'>
                                              <input className="input_03" type="text" name="title" value={formValues.title} maxLength={80} onChange={handleInputChange} />
                                              <label className="label_01">{formValues.title.length}/{80} {t.length}</label>
                                          </form>
                                      </td>
                                  </tr>
                                  <tr>
                                      <th>{t.content} {t.required}</th>
                                      <td>
                                          <form>
                                              <textarea className="textarea_box" id="" name="qContent" rows="" cols="" value={formValues.content} onChange={handleInputChange}></textarea>
                                          </form>
                                      </td>
                                  </tr>
                                  <tr>
                                      <th>{t.fileUpload}</th>
                                      <td>
                                          <form>
                                              <input type="file" name="file" onChange={handleFileChange} />
                                          </form>
                                          <p className="font_gray">{t.uploadFileNote}</p>
                                      </td>
                                  </tr>
                                  <tr>
                                      <th>{t.publicType}</th>
                                      <td>
                                          <div className="signup_05">
                                              <label className="check_box">
                                                  <input
                                                      type="radio"
                                                      name="notice"
                                                      value="Y"
                                                      checked={formValues.notice === 'Y'}
                                                      onChange={handleInputChange}
                                                  />
                                                  {t.public}
                                                  <span className="check_radio"></span>
                                              </label>
                                              <label className="check_box">
                                                  <input
                                                      type="radio"
                                                      name="notice"
                                                      value="N"
                                                      checked={formValues.notice === 'N'} 
                                                      onChange={handleInputChange}
                                                  />
                                                  {t.private}
                                                  <span className="check_radio"></span>
                                              </label>
                                          </div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </article>
  
                      <p className="table_04 font_gray">{t.note}</p>
                      <article className="button_box_01">
                          <NavLink to='/qna-list'>
                              <button type="button" className="button_01 button_03 button_gray_line">{t.cancel}</button>
                          </NavLink>
                          <button type="button" onClick={openModal} className="button_01 button_03 button_red" >{t.register}</button>
                      </article>
  
                  </section>
  
              </section>
          </main>
      </>
  );
  
}