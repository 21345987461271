const translations = {
  ko: {
    home: '홈',
    adminLogin: '관리자 로그인',
    username: '아이디',
    password: '비밀번호',
    keepLoggedIn: '로그인 유지',
    login: '로그인',
    findUsername: '아이디 찾기',
    findPassword: '비밀번호 찾기',
    signUp: '회원가입',
    mainTitle: '관리자 로그인',
    loginSuccess: '로그인 성공!',
    loginFailed: '로그인 실패!',
    storeSupport: '상점지원',
    serviceIntro: '서비스지원',
    loginMaintain: '로그인유지',
    loginId: '아이디',
    loginPassword: '비밀번호',
    loginButton: '로그인',
    logout: '로그아웃',
    myPage: '마이페이지',
    giftManagement: '기프트 관리',
    userManagement: '관리자 정보 관리',
    storeManagement: '상점 정보 관리',
    serviceInquiry: '서비스 문의',
    searchPeriod: '조회기간',
    giftId: '기프트 아이디',
    descendingOrder: '내림차순',
    ascendingOrder: '오름차순',
    saleQuantity: '판매수량',
    state: '상태',
    beforeSale: '판매전',
    inSale: '판매중',
    endSale: '판매종료',
    registrationDate: '등록일',
    search: '검색',
    reset: '초기화',
    giftImage: '기프트 이미지',
    price: '가격',
    totalSales: '누적판매수량',
    totalIssued: '총발행수량',
    totalSalesAmount: '누적판매금액',
    editOrSalesStatus: '수정/판매현황',
    edit: '수정',
    salesStatus: '판매현황',
    totalCount: '총건수',
    count: '건',
    registerGift: '기프트 등록',
    currency: '원',
    stateDesc: {
      '판매등록': '판매등록',
      '판매중지': '판매중지',
      '판매시작': '판매시작',
      '판매종료': '판매종료',
    },
    currentPassword: '기존 비밀번호',
    newPassword: '신규 비밀번호',
    confirmNewPassword: '신규 비밀번호 확인',
    changePassword: '비밀번호 변경',
    name: '이름',
    birthday: '생년월일',
    gender: '성별',
    male: '남자',
    female: '여자',
    email: '이메일',
    requestEmailAuth: '인증번호받기',
    validateEmailAuth: '인증하기',
    phoneNumber: '휴대폰번호',
    requestSMSAuth: '인증번호받기',
    validateSMSAuth: '인증하기',
    address: '주소',
    search: '찾기',
    registrationDate: '가입일시',
    modificationDate: '수정일시',
    userWithdrawal: '회원탈퇴',
    cancel: '취소',
    save: '저장',
    authCode: '인증번호',
    storeId: '상점 아이디',
    storeName: '상점명',
    storeLogo: '상점 로고/사진',
    uploadFileNote: '※ jpg,jpeg,gif,png 파일 첨부 가능, 1개 파일을 3MByte까지 업로드 할 수 있습니다.',
    phoneSameAsAdmin: '관리자 정보와 동일',
    selectBank: '입금받을 은행',
    bankAccountNumber: '입금받을 계좌번호',
    accountHolder: '예금주',
    giftQR: '선물하기 QR 코드',
    downloadQR: '선물하기 QR코드 저장하기',
    printQR: '선물하기 QR코드 프린트하기',
    delete: '삭제',
    inquiryType: '질문유형',
    questionTitle: '글 제목',
    author: '작성자',
    registrationDate: '등록 일자',
    select: '선택',
    title: '글 제목',
    titleContent: '제목+내용',
    content: '내용',
    public: '공개',
    private: '비공개',
    answer: '답변',
    askQuestion: '질문하기',
    no: 'No',
    questionType:"질문유형",
    qnaCategoryDesc: {
      '서비스 관련': '서비스 관련',
      '기프트 발행': '기프트 발행',
      '기프트 구매선물': '기프트 구매선물',
      '기프트 사용검증': '기프트 사용검증',
      '입금': '입금',
      '환불': '환불',
      '기타': '기타',
    },
    giftName: '기프트 명',
category: '카테고리',
giftPhoto: '기프트 사진',
giftAd: '기프트 광고',
price: '가격',
salePeriod: '판매기간',
validityPeriod: '유효기간',
totalIssuedQuantity: '총발행수량',
autoTransferTime: '자동송금시간',
registrationDateTime: '등록일시',
createAdText: '광고문구 생성',
giftAdSelect: '광고문구를 선택해주세요',
giftUnlimited: '무기한',
purchaseAfter: '구매후',
month: '개월',
day: '일',
unlimited: '무제한',
usageAfter: '사용후',
hour: '시간',
register: '등록',
salesStatus: '판매현황',
searchCondition: '--조회조건--',
paymentDate: '결제일',
usageDate: '사용일',
depositDate: '입금일',
refundRequestDate: '환불요청일',
refundCompletionDate: '환불완료일',
searchPeriod: '조회기간',
searchOrder: '--조회일--',
descendingOrder: '내림차순',
ascendingOrder: '오름차순',
giftId: '--기프트 아이디--',
paymentInfo: '--결제정보--',
creditCard: '신용카드',
bankTransfer: '계좌이체',
usageInfo: '--사용정보--',
unused: '미사용',
completed: '사용완료',
errorUsed: '기사용오류',
expired: '유효기간오류',
depositInfo: '--입금정보--',
notDeposited: '미입금',
deposited: '입금완료',
refundInfo: '--환불정보--',
refundRequest: '환불요청',
refundRequestCancel: '환불요청취소',
refundCompleted: '환불완료',
giftSerial: '기프트 시리얼',
gifter: '선물한 사람',
giftee: '선물받은 사람',
search: '검색',
reset: '초기화',
paymentDetails: '결제정보',
usageDetails: '사용정보',
depositDetails: '입금정보',
refundDetails: '환불정보',
totalCount: '총건수',
paymentAmount: '결제금액',
usageAmount: '사용금액',
depositAmount: '입금금액',
reservedAmount: '예치금액',
refundAmount: '환불금액',
serviceFee: '서비스이용료',
stopReason: '중지사유',
stopDateTime: '중지일시',
inputErrorStopReason: '입력오류: 중지사유를 입력해 주세요',
giftModifyDelete: '기프트 수정/삭제',
salesStop: '판매중지하기',
soldAmount: '누적판매금액',
soldQuantity: '누적판매수량',
status: '상태',
state: '상태',
salesState: '상태',
giftState: '상태',
home: '홈',
myPage: '마이페이지',
serviceInquiry: '서비스 문의',
category: '구분',
writer: '작성자',
title: '제목',
content: '내용',
fileUpload: '파일 업로드',
publicType: '공개유형',
public: '공개',
private: '비공개',
uploadFileNote: '※ jpg,jpeg,gif,png 파일 첨부 가능, 1개 파일을 3MByte까지 업로드 할 수 있습니다.',
note: '※ 고객님의 소중한 의견을 수렴하는 공간입니다. 내기프트에 바라는 내용을 등록해 주세요. 상업적 글이나 무고, 욕설, 비방, 개인정보 등과 관련된 글을 올리는 경우 삭제 될 수 있습니다.',
cancel: '취소',
register: '등록',
selectCategory: {
  serviceRelated: '서비스 관련',
  giftIssuance: '기프트 발행',
  giftPurchasePresent: '기프트 구매선물',
  giftUsageVerification: '기프트 사용검증',
  deposit: '입금',
  refund: '환불',
  others: '기타',
  '음식점': '음식점',
      '카페.디저트': '카페·디저트',
      '운동': '운동',
      '동네쿠폰': '동네쿠폰',
      '농수산물': '농수산물',
      '이사.용달': '이사·용달',
      '수리': '수리',
      '과외.클래스': '과외·클래스',
      '뷰티샵': '뷰티샵',
      '미용실': '미용실',
      '인테리어.시공': '인테리어·시공',
      '청소': '청소',
      '세탁소': '세탁소',
      '취미': '취미',
      '학원': '학원',
      '육아': '육아',
      '병원.약국': '병원·약국',
      '반려동물': '반려동물',
  },
  length: '글자 수',
  quantity: "개",
  movetogiftpage: "선물하기 페이지 이동하기",
},
en: {
  home: 'Home',
  adminLogin: 'Admin login',
  username: 'Username',
  password: 'Password',
  keepLoggedIn: 'Keep me logged in',
  login: 'Login',
  findUsername: 'Find username',
  findPassword: 'Find password',
  signUp: 'Sign up',
  mainTitle: 'Admin login',
  loginSuccess: 'Login successful!',
  loginFailed: 'Login failed!',
  storeSupport: 'Store support',
  serviceIntro: 'Service support',
  loginMaintain: 'Keep me logged in',
  loginId: 'Username',
  loginPassword: 'Password',
  loginButton: 'Login',
  logout: 'Logout',
  myPage: 'My page',
  giftManagement: 'Gift management',
  userManagement: 'User management',
  storeManagement: 'Store management',
  serviceInquiry: 'Service inquiry',
  searchPeriod: 'Period',
  giftId: 'Gift id',
  descendingOrder: 'Descending order',
  ascendingOrder: 'Ascending order',
  saleQuantity: 'Sales quantity',
  state: 'State',
  beforeSale: 'Before sale',
  inSale: 'In sale',
  endSale: 'End of sale',
  registrationDate: 'Registration date',
  search: 'Search',
  reset: 'Reset',
  giftImage: 'Gift image',
  price: 'Price',
  totalSales: 'Total sales',
  totalIssued: 'Total issued',
  totalSalesAmount: 'Total sales amount',
  editOrSalesStatus: 'Edit/sales status',
  edit: 'Edit',
  salesStatus: 'Sales Status',
  totalCount: 'Total count',
  count: 'Items',
  registerGift: 'Register gift',
  currency: 'KRW',
  stateDesc: {
    '판매등록': 'Sale registered',
    '판매중지': 'Sales discontinued',
    '판매시작': 'Sale started',
    '판매종료': 'Sale ended',
  },
  currentPassword: 'Current password',
  newPassword: 'New password',
  confirmNewPassword: 'Confirm new password',
  changePassword: 'Change password',
  name: 'Name',
  birthday: 'Birthday',
  gender: 'Gender',
  male: 'Male',
  female: 'Female',
  email: 'Email',
  requestEmailAuth: 'Request auth code',
  validateEmailAuth: 'Validate',
  phoneNumber: 'Phone number',
  requestSMSAuth: 'Request auth code',
  validateSMSAuth: 'Validate',
  address: 'Address',
  search: 'Search',
  registrationDate: 'Registration date',
  modificationDate: 'Modification date',
  userWithdrawal: 'Withdraw membership',
  cancel: 'Cancel',
  save: 'Save',
  authCode: 'Auth code',
  storeId: 'Store id',
  storeName: 'Store name',
  storeLogo: 'Store logo/image',
  uploadFileNote: '※ Jpg, jpeg, gif, png files are allowed. A maximum of 1 file up to 3mb can be uploaded.',
  phoneSameAsAdmin: 'Same as admin information',
  selectBank: 'Select bank',
  bankAccountNumber: 'Bank account number',
  accountHolder: 'Account holder',
  giftQR: 'Gift QR code',
  downloadQR: 'Download gift QR code',
  printQR: 'Print gift QR code',
  delete: 'Delete',
  inquiryType: 'Question type',
  questionTitle: 'Question title',
  author: 'Author',
  registrationDate: 'Registration date',
  select: 'Select',
  title: 'Title',
  titleContent: 'Title + content',
  content: 'Content',
  public: 'Public',
  private: 'Private',
  answer: 'Answer',
  askQuestion: 'Ask question',
  no: 'No',
  questionType: "Question type",
  qnaCategoryDesc: {
    '서비스 관련': 'Service related',
    '기프트 발행': 'Gift issuance',
    '기프트 구매선물': 'Gift purchase & present',
    '기프트 사용검증': 'Gift usage verification',
    '입금': 'Deposit',
    '환불': 'Refund',
    '기타': 'Others',
  },
  giftName: 'Gift name',
  category: 'Category',
  giftPhoto: 'Gift photo',
  giftAd: 'Gift ad',
  price: 'Price',
  salePeriod: 'Sale period',
  validityPeriod: 'Validity period',
  totalIssuedQuantity: 'Total issued quantity',
  autoTransferTime: 'Auto transfer time',
  registrationDateTime: 'Registration datetime',
  createAdText: 'Create ad text',
  giftAdSelect: 'Please select an ad text',
  giftUnlimited: 'Unlimited',
  purchaseAfter: 'After purchase',
  month: 'Month',
  day: 'Day',
  unlimited: 'Unlimited',
  usageAfter: 'After usage',
  hour: 'Hour',
  register: 'Register',
  salesStatus: 'Sales Status',
  searchCondition: '--Search condition--',
  paymentDate: 'Payment date',
  usageDate: 'Usage date',
  depositDate: 'Deposit date',
  refundRequestDate: 'Refund request date',
  refundCompletionDate: 'Refund completion date',
  searchOrder: '--Search order--',
  descendingOrder: 'Descending order',
  ascendingOrder: 'Ascending order',
  giftId: '--Gift id--',
  paymentInfo: '--Payment info--',
  creditCard: 'Credit card',
  bankTransfer: 'Bank transfer',
  usageInfo: '--Usage info--',
  unused: 'Unused',
  completed: 'Completed',
  errorUsed: 'Error used',
  expired: 'Expired',
  depositInfo: '--Deposit info--',
  notDeposited: 'Not deposited',
  deposited: 'Deposited',
  refundInfo: '--Refund info--',
  refundRequest: 'Refund request',
  refundRequestCancel: 'Refund request cancel',
  refundCompleted: 'Refund completed',
  giftSerial: 'Gift serial',
  gifter: 'Gifter',
  giftee: 'Giftee',
  search: 'Search',
  reset: 'Reset',
  paymentDetails: 'Payment info',
  usageDetails: 'Usage info',
  depositDetails: 'Deposit info',
  refundDetails: 'Refund info',
  totalCount: 'Total count',
  paymentAmount: 'Payment amount',
  usageAmount: 'Usage amount',
  depositAmount: 'Deposit amount',
  reservedAmount: 'Reserved amount',
  refundAmount: 'Refund amount',
  serviceFee: 'Service fee',
  stopReason: 'Stop reason',
  stopDateTime: 'Stop date & time',
  inputErrorStopReason: 'Input error: Please enter a stop reason',
  giftModifyDelete: 'Gift modify/delete',
  salesStop: 'Stop sale',
  soldAmount: 'Total sales amount',
  soldQuantity: 'Total sales quantity',
  status: 'Status',
  state: 'State',
  salesState: 'Sales state',
  giftState: 'Gift state',
  home: 'Home',
  myPage: 'My page',
  serviceInquiry: 'Service inquiry',
  category: 'Category',
  writer: 'Writer',
  title: 'Title',
  content: 'Content',
  fileUpload: 'File upload',
  publicType: 'Public type',
  public: 'Public',
  private: 'Private',
  uploadFileNote: '※ Jpg, jpeg, gif, png files are allowed. A maximum of 1 file up to 3mb can be uploaded.',
  note: '※ This space is for collecting your valuable opinions. Please register your suggestions for the service. Posts related to commercial purposes, defamation, slander, personal information, etc. may be deleted.',
  cancel: 'Cancel',
  register: 'Register',
  selectCategory: {
    serviceRelated: 'Service related',
    giftIssuance: 'Gift issuance',
    giftPurchasePresent: 'Gift purchase & present',
    giftUsageVerification: 'Gift usage verification',
    deposit: 'Deposit',
    refund: 'Refund',
    others: 'Others',
    '음식점': 'Restaurant',
    '카페.디저트': 'Cafe·dessert',
    '운동': 'Exercise',
    '동네쿠폰': 'Local coupon',
    '농수산물': 'Agricultural products',
    '이사.용달': 'Moving·delivery',
    '수리': 'Repair',
    '과외.클래스': 'Tutoring·class',
    '뷰티샵': 'Beauty shop',
    '미용실': 'Hair salon',
    '인테리어.시공': 'Interior·construction',
    '청소': 'Cleaning',
    '세탁소': 'Laundry',
    '취미': 'Hobby',
    '학원': 'Academy',
    '육아': 'Childcare',
    '병원.약국': 'Hospital·pharmacy',
    '반려동물': 'Pet',
},
length: 'Characters',
quantity: "Quantity",
movetogiftpage: "Go to gift page",
},
};

export default translations;





